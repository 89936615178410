// Import the functions you need from the SDKs you need
import 'firebase/firestore'
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import imageCompression from "browser-image-compression";
import { getBlob } from "@firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC5IvOGDbAqMO-T-Q78RVIqHXNku8vdDD4",
    authDomain: "my.faismoibg.com",
    projectId: "faismoibg-app",
    storageBucket: "faismoibg-app.appspot.com",
    messagingSenderId: "1062102549014",
    appId: "1:1062102549014:web:52e129f8f395ed04c62b37",
    measurementId: "G-YF0ZB2Y2X4"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics;
const auth = firebase.auth();
// Check if user exists
async function checkUserExists(uid) {
    try {
        const checkExists = firebase.functions().httpsCallable('checkExists');
        const result = await checkExists({checkType:"uid", value:uid});
        return result.data.result;
    } catch (error) {
        // Handle any errors that occur during the process
        console.error('Error checking user existence:', error);
        return false;
    }
}

export const createUserWithEmailAndPassword = async (emaila, password, firstName, lastName) => {
    try {
        // Create a new user with email and password
        const result = await firebase.auth().createUserWithEmailAndPassword(emaila, password);

        // Get the user's ID and email
        const { user } = result;
        const { uid, email } = user;

        // Store the user data in your database or perform other actions
        // Example: saving the user to Firestore
        await firebase.firestore().collection('users').doc(uid).set({
            prenom: firstName,
            nom: lastName,
            email: email,
            createdAt: new Date(),
            postCreateDone: false,
        }, { merge: true });

        // Return the user data if needed
        return { uid, firstName, lastName, email };
    } catch (error) {
        // Handle any errors that occur during the user creation process
        console.error('Error creating user with email and password:', error);
        throw error;
    }
};
// Create a new user with Google provider
export const createUserWithGoogle = async () => {

    const provider = new firebase.auth.GoogleAuthProvider();

    try {
        // Sign in with the Google popup window
        const result = await auth.signInWithPopup(provider);
        // Get the user's ID token, display name, and email
        const { user } = result;
        const { uid, displayName, email } = user;

        // Extract first name and last name from the display name
        let firstName = '';
        let lastName = '';
        if (displayName) {
            const nameParts = displayName.split(' ');
            firstName = nameParts[0] || '';
            lastName = nameParts[1] || '';
        }

        const userExists = await checkUserExists(uid);

        if (userExists){
            return { uid, firstName, lastName, email };
        }

        // Store the user data in your database or perform other actions
        // Example: saving the user to Firestore
        await firebase.firestore().collection('users').doc(uid).set({
            prenom:firstName,
            nom:lastName,
            email: email,
            createdAt: new Date(),
            postCreateDone: false,
        }, {merge:true});

        // Return the user data if needed
        return { uid, firstName, lastName, email };
    } catch (error) {
        // Handle any errors that occur during the sign-in process
        console.error('Error creating user with Google:', error);
        throw error;
    }
};

export const getUserPostCreateStatus = async (uid) => {
    try {
        const userRef = firebase.firestore().collection('users').doc(uid);
        const doc = await userRef.get();

        if (doc.exists) {
            const data = doc.data();
            return data.postCreateDone || false;
        }

        return false; // Document does not exist, return false
    } catch (error) {
        throw error;
    }
};

export const getUserSubscription = async (uid) => {
    try {
        const userRef = firebase.firestore().collection('subscriptions').doc(uid);
        const doc = await userRef.get();

        if (doc.exists) {
            return doc.data();
        }
        return false; // Document does not exist, return false
    } catch (error) {
        throw error;
    }
};

export const getUser = async (uid) => {
    try {
        const docRef = firebase.firestore().collection('users').doc(uid);
        const docSnapshot = await docRef.get();
        if (docSnapshot.exists) {
            return docSnapshot.data();
        } else {
            // Document with the given ID does not exist
            return null;
        }
    } catch (error) {
        console.error('Error fetching document:', error);
        return null;
    }
};

export const getRdvById = async (id) => {
    try {
        const userRef = firebase.firestore().collection('rdv').doc(id);
        const doc = await userRef.get();

        if (doc.exists) {
            return doc.data();
        }
        return false; // Document does not exist, return false
    } catch (error) {
        throw error;
    }
};

export const deleteImageByLink = async (imageUrl) => {
    try {
        // Get a reference to the image using its URL
        const imageRef = firebase.storage().refFromURL(imageUrl);

        // Delete the image
        await imageRef.delete();
    } catch (error) {
        console.error('Error deleting image', error);
    }
};

export const getPrestaDoc = async (id) => {
    try {
        const docRef = firebase.firestore().collection('presta').doc(id);
        const docSnapshot = await docRef.get();
        if (docSnapshot.exists) {
            return docSnapshot.data();
        } else {
            // Document with the given ID does not exist
            return null;
        }
    } catch (error) {
        console.error('Error fetching document:', error);
        return null;
    }
};

export const getAgendaDoc = async (uid) => {
    try {
        const docRef = firebase.firestore().collection('agenda').doc(uid);
        const docSnapshot = await docRef.get();
        if (docSnapshot.exists) {
            return docSnapshot.data();
        } else {
            // Document with the given ID does not exist
            return null;
        }
    } catch (error) {
        console.error('Error fetching document:', error);
        return null;
    }
};

export async function loginEmailUser(email, password) {
    try {
        const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
        const user = userCredential.user;
        return user;
    } catch (error) {
        console.error('Error logging in:', error.message);
        throw error;
    }
}

export async function changeUserEmail(newEmail, password) {
    try {
        const user = firebase.auth().currentUser;

        if (!user) {
            throw new Error("User not authenticated.");
        }

        const credential = firebase.auth.EmailAuthProvider.credential(
            user.email,
            password
        );

        await user.reauthenticateWithCredential(credential);
        await user.updateEmail(newEmail);
    } catch (error) {
        console.error("An error occurred:", error.message);
        throw new Error(error);
    }
}

export const getUserRdvByDocId = async (docId) => {
    try {
        const docRef = firebase.firestore().collection("rdv").doc(docId);

        const doc = await docRef.get();

        if (doc.exists) {
            return [doc.data()];
        } else {
            // No documents found with the given uid in the 'presta' collection
            return [];
        }
    } catch (error) {
        console.error('Error fetching documents:', error);
        return null;
    }
}

/*export const getUserRdvByCancelId = async (cancelId) => {
    try {
        const docRef = firebase.firestore().collection("rdv")
            .where("cancelId", "==", cancelId)

        const docSnapshot = await docRef.get();

        if (!docSnapshot.empty) {
            const docDataArray = docSnapshot.docs.map((doc) => doc.data());
            return docDataArray;
        } else {
            // No documents found with the given uid in the 'presta' collection
            return [];
        }
    } catch (error) {
        console.error('Error fetching documents:', error);
        return null;
    }
}*/
export const getUserRdvPerDate = async (uid, month, day, year, isWholeMonth=false) => {
    try {
        let docRef;
        if (!isWholeMonth){
             docRef = firebase.firestore().collection("rdv")
                .where("uid", "==", uid)
                .where("year", "==", year)
                .where("month", "==", month)
                .where("day", "==", day)
        } else {
            docRef = firebase.firestore().collection("rdv")
                .where("uid", "==", uid)
                .where("year", "==", year)
                .where("month", "==", month)
                .where("isCanceled", "==", false)
                .where("isValidated", "==", true)
        }

        const docSnapshot = await docRef?.get();

        if (!docSnapshot?.empty) {
            const docDataArray = docSnapshot.docs.map((doc) => doc.data());
            return docDataArray;
        } else {
            // No documents found with the given uid in the 'presta' collection
            return [];
        }
    } catch (error) {
        console.error('Error fetching documents:', error);
        return null;
    }
};

export const getUserPendingRdv = async (uid, now) => {
    try {
        const docRef = firebase.firestore().collection("rdv")
            .where("uid", "==", uid)
            .where("isValidated", "==", false)
            .where("isCanceled", "==", false)
            .where("start", ">", now)

        const docSnapshot = await docRef.get();

        if (!docSnapshot.empty) {
            const docDataArray = docSnapshot.docs.map((doc) => doc.data());
            return docDataArray;
        } else {
            // No documents found with the given uid in the 'presta' collection
            return [];
        }
    } catch (error) {
        console.error('Error fetching documents:', error);
        return null;
    }
};

export const completePasswordReset = async (oobCode, newPassword) => {
    try {
        await firebase.auth().verifyPasswordResetCode(oobCode);
        await firebase.auth().confirmPasswordReset(oobCode, newPassword);
    } catch (error) {
        console.error("Error completing password reset:", error);
    }
};

export const resetPassword = async (email) => {
    try {
        await firebase.auth().sendPasswordResetEmail(email);
        return true; // Success
    } catch (error) {
        console.error("Error sending password reset email:", error);
        return false; // Error
    }
};

export const getUserPresta = async (uid) => {
    try {
        const docRef = firebase.firestore().collection('presta')
            .where('uid', '==', uid)
            .where('prestaDeleted', '==', false);
        const docSnapshot = await docRef.get();

        if (!docSnapshot.empty) {
            const docDataArray = docSnapshot.docs.map((doc) => doc.data());
            return docDataArray;
        } else {
            return [];
        }
    } catch (error) {
        console.error('Error fetching documents:', error);
        return null;
    }
};

export const checkIfPseudoExists = async (pseudo) => {
    try {
        const checkExists = firebase.functions().httpsCallable('checkExists');
        const result = await checkExists({checkType:"pseudo", value:pseudo});
        return result.data.result;
    } catch (error) {
        console.error('Error checking pseudo:', error);
        throw new Error("something went wrong");
    }
};

export const checkIfEmailExists = async (email) => {
    try {
        const checkExists = firebase.functions().httpsCallable('checkExists');
        const result = await checkExists({checkType:"email", value:email});
        return result.data.result;
    } catch (error) {
        console.error('Error checking email:', error);
        throw new Error("something went wrong");
    }
};

export const checkIfUrlExists = async (url) => {
    try {
        const checkExists = firebase.functions().httpsCallable('checkExists');
        const result = await checkExists({checkType:"url", value:url});
        return result.data.result;
    } catch (error) {
        console.error('Error checking url:', error);
        throw new Error("something went wrong");
    }
};

export const updateUserDocument = async (uid, dataToUpdate) => {
    try {
        // Get a reference to the Firestore collection and document
        const collectionRef = firebase.firestore().collection('users').doc(uid);

        // Update the document with the provided data, merging it with the existing data
        await collectionRef.set(dataToUpdate, { merge: true });

        // Return true if the update is successful
        return true;
    } catch (error) {
        // Throw an error if something goes wrong
        throw new Error(`Error updating user document with uid '${uid}': ${error.message}`);
    }
};

export const validateRdvById = async (dataToUpdate, id) => {
    try {

        // Update the matched document with the provided data, merging it with the existing data
        await firebase.firestore().collection("rdv").doc(id).set(dataToUpdate, { merge: true });
        // Return the ID of the updated document
        return id;
    } catch (error) {
        // Throw an error if something goes wrong
        throw new Error(`Error updating presta: ${error.message}`);
    }
};

export const cancelRdvById = async (dataToUpdate, id) => {
    try {

        // Update the matched document with the provided data, merging it with the existing data
        await firebase.firestore().collection("rdv").doc(id).set(dataToUpdate, { merge: true });
        // Return the ID of the updated document
        return id;
    } catch (error) {
        // Throw an error if something goes wrong
        throw new Error(`Error updating presta: ${error.message}`);
    }
};

/*
export const cancelRdv = async (dataToUpdate, cancelId) => {
    try {
        // Get a reference to the Firestore collection
        const collectionRef = firebase.firestore().collection('rdv');

        // Query for the first document that matches the cancelId
        const querySnapshot = await collectionRef.where('cancelId', '==', cancelId).limit(1).get();

        // Check if a matching document was found
        if (!querySnapshot.empty) {
            const documentSnapshot = querySnapshot.docs[0];
            const documentId = documentSnapshot.id;

            // Update the matched document with the provided data, merging it with the existing data
            await collectionRef.doc(documentId).set(dataToUpdate, { merge: true });

            // Return the ID of the updated document
            return documentId;
        } else {
            // No matching document found
            throw new Error('No document with matching cancelId found.');
        }
    } catch (error) {
        // Throw an error if something goes wrong
        throw new Error(`Error updating presta: ${error.message}`);
    }
};
*/

export const updateRdv = async (dataToUpdate, isAllSlots) => {

    try {
        const updateRdvPresta = firebase.functions().httpsCallable('updateRdvPresta');
        const currentDate = new Date(dataToUpdate.year, dataToUpdate.month, dataToUpdate.day);
        const offsetInMinutes = currentDate.getTimezoneOffset();
        const offsetInMilliseconds = offsetInMinutes * 60 * 1000;
        const adjustedDate = new Date(currentDate.getTime() + Math.abs(offsetInMilliseconds));
        const result = await updateRdvPresta({dataToUpdate:dataToUpdate, inputDate:adjustedDate, isAllSlots:isAllSlots});
        return result.data.docId;

    } catch (error) {
        console.error(`Error updating rdv: ${error.message}`);
        throw new Error(`Error updating rdv: ${error.message}`);
    }
};



/*export const updateRdv= async (dataToUpdate) => {
    try {
        // Get a reference to the Firestore collection
        const collectionRef = firebase.firestore().collection('rdv').doc();

        // Add the document ID to the dataToUpdate object
        dataToUpdate.documentId = collectionRef.id;

        // Update the document with the provided data, merging it with the existing data
        await collectionRef.set(dataToUpdate, { merge: true });

        // Return true if the update is successful
        return collectionRef.id;
    } catch (error) {
        // Throw an error if something goes wrong
        throw new Error(`Error updating presta: ${error.message}`);
    }
};*/


export const updatePresta = async (dataToUpdate, docId) => {
    try {
        const collectionRef = firebase.firestore().collection('presta').doc(docId);
        dataToUpdate.documentId = collectionRef.id;
        await collectionRef.set(dataToUpdate, { merge: true });
        return true;
    } catch (error) {
        // Throw an error if something goes wrong
        throw new Error(`Error updating presta: ${error.message}`);
    }
};

export const updateAgenda = async (dataToUpdate, uid) => {
    try {
        const collectionRef = firebase.firestore().collection('agenda').doc(uid);
        dataToUpdate.documentId = collectionRef.id;
        await collectionRef.set(dataToUpdate, { merge: true });
        return true;
    } catch (error) {
        // Throw an error if something goes wrong
        throw new Error(`Error updating agenda: ${error.message}`);
    }
};

export const createPresta = async (dataToUpdate) => {
    try {
        const collectionRef = firebase.firestore().collection('presta').doc();
        dataToUpdate.documentId = collectionRef.id;
        await collectionRef.set(dataToUpdate, { merge: true });
        return collectionRef.id;
    } catch (error) {
        // Throw an error if something goes wrong
        throw new Error(`Error updating presta: ${error.message}`);
    }
};

export const createBloc = async (dataToUpdate) => {
    try {
        const collectionRef = firebase.firestore().collection('bloc').doc();
        dataToUpdate.documentId = collectionRef.id;
        await collectionRef.set(dataToUpdate, { merge: true });
        return collectionRef.id;
    } catch (error) {
        // Throw an error if something goes wrong
        throw new Error(`Error updating presta: ${error.message}`);
    }
};

export const updateBloc = async (dataToUpdate, docId) => {
    try {
        const collectionRef = firebase.firestore().collection('bloc').doc(docId);
        await collectionRef.set(dataToUpdate, { merge: true });
        return true;
    } catch (error) {
        // Throw an error if something goes wrong
        throw new Error(`Error updating presta: ${error.message}`);
    }
};

export const logout = async () => {
    try {
        await firebase.auth().signOut();
    } catch (error) {
        // An error occurred
        console.error('Error while logging out:', error);
    }
};

export const duplicateImageFromURL = async (existingImageUrl) => {
    try {
        // Reference to the existing path in Firebase Storage
        const storage = firebase.storage();
        const storageRef = storage.refFromURL(existingImageUrl);
        // Get existing image as blob
        const blob = await getBlob(storageRef)
        // Reference to the new path in Firebase Storage
        const newImageRef = storage.ref('presta/' + firebase.auth().currentUser.uid + '/' + uuidv4());
        // Upload blob to the new path
        await newImageRef.put(blob);
        // Get the new image URL
        const newImageUrl = await newImageRef.getDownloadURL();

        return newImageUrl;
    } catch (error) {
        console.log('Error duplicating image:', error);
        throw new Error('Error duplicating image:', error);
    }
};

export const handleImageUpload = async (file, isBloc=false) => {
    if (file) {
        const supportedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
        let shouldCompress = false;
        if (supportedFormats.includes(file.type)) {
            shouldCompress=true;
        }
        const compressedFile = shouldCompress ? await imageCompression(file, {
            maxSizeMB: 5, // Adjust the maximum size as needed
            maxWidthOrHeight: 1920, // Adjust the maximum width or height as needed
            useWebWorker: true,
        }) : file;
        const storageRef = firebase.storage().ref();
        const imageRef = storageRef.child((isBloc ? 'bloc/' : 'presta/') +firebase.auth().currentUser.uid+'/'+uuidv4());
        try {
            await imageRef.put(compressedFile);
            const url = await imageRef.getDownloadURL();
            return url;
        } catch (error) {
            console.log('Error uploading image:', error);
        }
    }
};
export const handleUpload = async (file) => {
    if (file) {
        const supportedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
        let shouldCompress = false;
        if (supportedFormats.includes(file.type)) {
            shouldCompress=true;
        }
        const compressedFile = shouldCompress ? await imageCompression(file, {
            maxSizeMB: 5, // Adjust the maximum size as needed
            maxWidthOrHeight: 1920, // Adjust the maximum width or height as needed
            useWebWorker: true,
        }) : file;
        const storageRef = firebase.storage().ref();
        const imageRef = storageRef.child(`profilepic/${firebase.auth().currentUser.uid}`);
        try {
            await imageRef.put(compressedFile);
            const userRef = firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid);
            const url = await imageRef.getDownloadURL();
            await userRef.set({
                imageUrl: url,
            },{ merge: true });
            return url;
        } catch (error) {
            console.log('Error uploading image:', error);
        }
    }
};

export const handleDelete = async (imageUrl) => {
    if (imageUrl) {
        const storageRef = firebase.storage().refFromURL(imageUrl);
        try {
            await storageRef.delete();
            // Clear Firestore field
            const userRef = firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid);
            await userRef.update({
                profileImageUrl: '',
            });
        } catch (error) {
            console.log('Error deleting image:', error);
        }
    }
};

export const getUnavSlots = async (uid) => {
    try {
        const docRef = firebase.firestore().collection('unavSlots')
            .where('uid', '==', uid)
            .where('end', '>', new Date());
        const docSnapshot = await docRef.get();

        if (!docSnapshot.empty) {
            const docDataArray = docSnapshot.docs.map((doc) => doc.data());
            return docDataArray;
        } else {
            return [];
        }
    } catch (error) {
        throw new Error(`Error updating presta: ${error.message}`);
    }
};

export const createUnavSlot = async (dataToUpdate) => {
    try {
        const collectionRef = firebase.firestore().collection('unavSlots').doc();
        dataToUpdate.documentId = collectionRef.id;
        await collectionRef.set(dataToUpdate, { merge: true });
        return collectionRef.id;
    } catch (error) {
        // Throw an error if something goes wrong
        throw new Error(`Error updating presta: ${error.message}`);
    }
};

export const deleteUnavSlot = async (documentId) => {
    try {
        const docRef = firebase.firestore().collection('unavSlots').doc(documentId);
        await docRef.delete();
        return { success: true, message: 'Document successfully deleted' };
    } catch (error) {
        throw new Error(`Error deleting document: ${error.message}`);
    }
};

export const countRes = async (monthsArray, yearsArray) => {
    try {
        // Convert month and year arrays to integers if not empty
        const convertedMonthsArray = monthsArray.length > 0
            ? monthsArray.map(month => parseInt(month, 10))
            : [];
        const convertedYearsArray = yearsArray.length > 0
            ? yearsArray.map(year => parseInt(year, 10))
            : [];
        if (convertedMonthsArray.length<1 || convertedYearsArray.length<1) return {count:0};
        const countRes = await firebase.functions().httpsCallable('countResa');
        const result = await countRes({monthsArray:convertedMonthsArray, yearsArray:convertedYearsArray});
        return result.data;
    } catch (error) {
        console.error('Error computing count:', error);
        throw new Error("something went wrong");
    }
};


export const updateSortIndexes = async (documentsToUpdate, isBloc=false) => {
    let collectionName="presta";
    if (isBloc) collectionName="bloc";
    try {
        const firestore = firebase.firestore();
        const batch = firestore.batch(); // Firestore batch write for parallel updates

        documentsToUpdate.forEach((doc) => {
            const { documentId, isSorted, sortIndex } = doc;

            if (!documentId) {
                throw new Error("documentId is required for each document.");
            }

            const docRef = firestore.collection(collectionName).doc(documentId);

            // Update or create the document with isSorted and sortIndex
            batch.set(
                docRef,
                {
                    isSorted: isSorted,
                    sortIndex: sortIndex,
                },
                { merge: true } // Merge ensures we only update these fields
            );
        });

        // Commit the batch write
        await batch.commit();
    } catch (error) {
        console.error(`Error updating documents: ${error.message}`);
        throw new Error(`Error updating documents: ${error.message}`);
    }
};

export const getUserBlocs = async (uid) => {
    try {
        const docRef = firebase.firestore().collection('bloc')
            .where('uid', '==', uid)
            .where('blocDeleted', '==', false);
        const docSnapshot = await docRef.get();

        if (!docSnapshot.empty) {
            const docDataArray = docSnapshot.docs.map((doc) => doc.data());
            return docDataArray;
        } else {
            return [];
        }
    } catch (error) {
        console.error('Error fetching documents:', error);
        throw new Error(`Error getting blocs: ${error.message}`);
    }
};

export const getBlocDoc = async (id) => {
    try {
        const docRef = firebase.firestore().collection('bloc').doc(id);
        const docSnapshot = await docRef.get();
        if (docSnapshot.exists) {
            return docSnapshot.data();
        } else {
            // Document with the given ID does not exist
            return null;
        }
    } catch (error) {
        console.error('Error fetching document:', error);
        throw new Error(error);
    }
};

export const getCustomers = async () => {
    try {
        const getCust = await firebase.functions().httpsCallable('getCustomers');
        const result = await getCust();
        return result.data;
    } catch (error) {
        console.error('Error computing count:', error);
        throw new Error("something went wrong");
    }
};

export const getClientsRdv = async (emailNormalized, uid) => {
    try {
        let docRef;
        docRef = firebase.firestore().collection("rdv")
                .where("uid", "==", uid)
                .where('isValidated', '==', true)
                .where('isCanceled', '==', false)
                .where("custAddressNormalized", "==", emailNormalized)

        const docSnapshot = await docRef?.get();

        if (!docSnapshot?.empty) {
            const docDataArray = docSnapshot.docs.map((doc) => doc.data());
            return docDataArray;
        } else {
            // No documents found with the given uid in the 'presta' collection
            return [];
        }
    } catch (error) {
        console.error('Error fetching documents:', error);
        throw new Error("something went wrong");
    }
};

export const updateBlackList = async (bEmail, isBlackList, uid) => {
    try {
        const docRef = firebase.firestore().collection('blacklist').doc(uid);
        const docSnapshot = await docRef.get();
        if (docSnapshot.exists) {
            const data = docSnapshot.data();
            if (isBlackList && !data.blacklist.some(email=>email===atob(bEmail))){
                await docRef.update({
                    blacklist: firebase.firestore.FieldValue.arrayUnion(atob(bEmail))
                });
            } else if (!isBlackList && data.blacklist.some(email=>email===atob(bEmail))){
                await docRef.update({
                    blacklist: firebase.firestore.FieldValue.arrayRemove(atob(bEmail))
                });
            }
        } else if (isBlackList) {
            await docRef.set({
                blacklist: [atob(bEmail)],
                uid:uid,
            });
        }
    } catch (error) {
        console.error('Error updating blacklist document:', error);
        throw new Error(`Error updating blacklist: ${error.message}`);
    }
};

export const updateComment = async (bEmail, comment, uid) => {
    const docRef = firebase.firestore().collection('comment').doc(bEmail+":"+uid);
    try {
        await docRef.set({
            comment: comment,
            bEmail:bEmail,
            uid:uid}, {merge:true});
    } catch (error) {
        console.error('Error updating comment:', error);
        throw new Error(`Error updating comment: ${error.message}`);
    }
}

export const getCommentDoc = async (cid, uid) => {
    try {
        const docRef = firebase.firestore().collection('comment').doc(cid+":"+uid);
        const docSnapshot = await docRef.get();
        if (docSnapshot.exists) {
            return docSnapshot.data();
        } else {
            // Document with the given ID does not exist
            return null;
        }
    } catch (error) {
        console.error('Error fetching document:', error);
        throw new Error(error);
    }
};

export const getBlackListDoc = async (uid) => {
    try {
        const docRef = firebase.firestore().collection('blacklist').doc(uid);
        const docSnapshot = await docRef.get();
        if (docSnapshot.exists) {
            return docSnapshot.data();
        } else {
            // Document with the given ID does not exist
            return null;
        }
    } catch (error) {
        console.error('Error fetching document:', error);
        throw new Error(error);
    }
};

export const getPrestaStats = async () => {
    try {
        const getPrestaStats = await firebase.functions().httpsCallable('getPrestaStats');
        const result = await getPrestaStats();
        return result.data;
    } catch (error) {
        console.error('Error computing count:', error);
        throw new Error("something went wrong");
    }
};

export const getLatLng = async (data) => {
    try {
        const getLatLng = await firebase.functions().httpsCallable('getLatLng');
        const result = await getLatLng(data);
        return result.data;
    } catch (error) {
        console.error('Error computing count:', error);
        throw new Error("something went wrong");
    }
};

export const updateSmsDocument = async (uid, dataToUpdate) => {
    try {
        // Get a reference to the Firestore collection and document
        const collectionRef = firebase.firestore().collection('sms').doc(uid);

        // Update the document with the provided data, merging it with the existing data
        await collectionRef.set(dataToUpdate, { merge: true });

        // Return true if the update is successful
        return true;
    } catch (error) {
        // Throw an error if something goes wrong
        throw new Error(`Error updating sms document with uid '${uid}': ${error.message}`);
    }
};

export const getSmsData = async (uid) => {
    try {
        const docRef = firebase.firestore().collection('sms').doc(uid);
        const docSnapshot = await docRef.get();
        if (docSnapshot.exists) {
            return docSnapshot.data();
        } else {
            // Document with the given ID does not exist
            return null;
        }
    } catch (error) {
        console.error('Error fetching document:', error);
        return null;
    }
};