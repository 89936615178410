
//BELOW WORKS
/*
import React, { useCallback, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import debounce from 'lodash.debounce';
import {useMediaQuery, useTheme} from "@mui/material";


const AutocompleteAddressFrance = ({ onAddressSelected }) => {
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const getOptions = useCallback(
        debounce(async (inputValue) => {
            try {
                if (inputValue.length >= 3) {
                    const service = new window.google.maps.places.AutocompleteService();
                    service.getPlacePredictions(
                        {
                            input: inputValue,
                            componentRestrictions: { country: 'fr' },
                            types: ['address'],
                        },
                        (predictions, status) => {
                            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                                setOptions(predictions || []);
                            } else {
                                setOptions([]);
                            }
                        }
                    );
                } else {
                    setOptions([]);
                }
            } catch (error) {
                console.error('Error fetching address predictions', error);
            }
        }, 300),
        []
    );

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        getOptions(newInputValue);
    };

    const handleAddressSelected = (event, newValue) => {
        if (newValue) {
            onAddressSelected(newValue);
        }
    };

    const getOptionStyle = () => ({
        borderRadius: "15px", // Set the border radius for each option
        margin: "10px 0", // Add space between the list items
        background: "#DAE0E5", // Set background color for each option
        padding: "15px", // Add padding for each option
    });



    return (
        <Autocomplete
            className="custom-autocomplete button-autocomplete"
            style={{ background: '#F1F5F8', borderRadius: '15px', width: '100%' }}
            options={options}
            noOptionsText="Aucun choix dispo"
            getOptionLabel={(option) => option.description || ''}
            value={null}
            onChange={handleAddressSelected}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            renderOption={(props, option, { selected }) => {
                console.log(option);
                return <li style={getOptionStyle()}  {...props}>{option.description}</li>;
            }}
            renderInput={(params) => (
                <TextField {...params} label="Adresse" variant="outlined" />
            )}
        />
    );
};

export default AutocompleteAddressFrance;*/

import React, {useCallback, useEffect, useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import debounce from 'lodash.debounce';

const GOOGLE_API_URL =
    'https://maps.googleapis.com/maps/api/js?key=YOUR_GOOGLE_MAPS_API_KEY&libraries=places&language=fr';

const AutocompleteAddressFrance =  ({ onAddressSelected }) => {
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState('');

    const getOptions = useCallback(
        debounce(async (inputValue) => {
            try {
                if (inputValue.length >= 3) {
                    const service = new window.google.maps.places.AutocompleteService();
                    await service.getPlacePredictions(
                        {
                            input: inputValue,
                            componentRestrictions: { country: ['fr', 'gp', 'mq', 'be', 'ch'] },
                            types: ['address'],
                        },
                        (predictions, status) => {
                            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                                setOptions(predictions || []);
                            } else {
                                setOptions([]);
                            }
                        }
                    );
                } else {
                    setOptions([]);
                }
            } catch (error) {
                console.error('Error fetching address predictions', error);
            }
        }, 300),
        []
    );

    const handleInputChange = (event, newInputValue) => {
        if (event && event.type!=="blur"){
            setInputValue(newInputValue);
            getOptions(newInputValue);
        }
    };

    const handleAddressSelected = (event, newValue) => {
        if (newValue) {
            // If a value is selected, send its description as the address
            setValue(newValue.description);
            onAddressSelected(newValue.description);
        }
    };

    const isOptionEqualToValue = (option, value) =>
        value === option.description;

    const getOptionStyle = () => ({
        borderRadius: "15px",
        margin: "10px 0",
        background: "#DAE0E5",
        padding: "15px",
    });

    useEffect(() => {

        if (!window.googleMapsScriptAdded) {
            // Load Google Maps script with API key
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB527GIyszXJqH7x3TqLNa1uq-k6eeECnI&libraries=places&language=fr&callback=Function.prototype`;
            script.async = true;
            document.body.appendChild(script);
            script.onload = () => {
                // Script has loaded
            };
            window.googleMapsScriptAdded = true;
            return () => {
            };
        }

    },[]);

    return (
        <Autocomplete
            className="custom-autocomplete button-autocomplete"
            style={{ background: '#F1F5F8', borderRadius: '15px', width: '100%' }}
            options={options}
            noOptionsText="Aucun choix dispo"
            getOptionLabel={(option) => option.description || ''}
            value={value}
            isOptionEqualToValue={isOptionEqualToValue}
            onChange={handleAddressSelected}
            inputValue={inputValue}
            filterOptions={(options) => options}
            onInputChange={handleInputChange}
            renderOption={(props, option, { selected }) => {
                return <li style={getOptionStyle()} {...props}>{option.description}</li>;
            }}
            renderInput={(params) => (
                <TextField {...params} label="rue, ville, pays" variant="outlined" />
            )}
        />
    );
};

export default AutocompleteAddressFrance;
