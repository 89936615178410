import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {HeaderHome} from "./headerHome";
import {
    Backdrop,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    MenuItem,
    Skeleton,
    Typography, useMediaQuery, useTheme
} from "@mui/material";
import firebase from "firebase/compat/app";
import 'firebase/firestore'
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import {cancelRdvById, getUser, getUserPendingRdv, validateRdvById} from "../firebase";
import SnackbarWithCustomBackground from "./snackbar";
import userGreyIc from "../assets/userGreyCircleIc.svg";
import checkGreyIc from "../assets/checkGreyIc.svg";
import Button from "@mui/material/Button";
import checkGreenIc from "../assets/ic_check.svg";
import icCancel from "../assets/cancelRound.svg";

function convertToTimeZone(date, timezone) {


    const options = {
        timeZone: timezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    };

    const formatter = new Intl.DateTimeFormat('en-US', options);
    const formattedDate = formatter.format(date);
    return new Date(formattedDate);
}

function formatHour(date) {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}h${minutes}`;
}

function formatDate(date) {
    const dayNames = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
    const monthNames = Object.keys(monthMap);
    return `${dayNames[date.getDay()]} ${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
}

const monthMap = {
    "janvier": 0,
    "février": 1,
    "mars": 2,
    "avril": 3,
    "mai": 4,
    "juin": 5,
    "juillet": 6,
    "août": 7,
    "septembre": 8,
    "octobre": 9,
    "novembre": 10,
    "décembre": 11
};

const cancelOptions = ["Double réservation", "Imprévu Santé", "Non versement d’acompte", "Nouvelle réservation proposée",
    "Problèmes de locaux", "Retard conséquent du client", "Urgence personnelle", "Vacances ou absence", "Autre"];
export default function Notifications() {

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [notifications, setNotifications] = useState('');
    const [showBackDrop, setShowBackDrop] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedCancel, setSelectedCancel] = useState("");
    const [isAlreadyRefunded, setIsAlreadyRefunded] = useState(false);
    const [currentNotif, setCurrentNotif] = useState(null);
    const [index, setCurrentIndex] = useState(null);
    const [userData, setUserData] = useState(null);
    const cancelEvent = firebase.functions().httpsCallable('cancelEvent');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(async (user) => {
            if (!user) {
                navigate("/");
            } else {
                const fetchData = async () => {
                    const currentDate = new Date();
                    try {
                        const userData = await getUser(user.uid);
                        const notifs = await getUserPendingRdv(user.uid, new Date());
                        setNotifications(notifs);
                        setUserData(userData);
                    } catch (e) {
                        handleOpenSnackbar("Quelque chose s'est mal passé, réessayez ou contactez nous")
                        console.error('Error ', e.message);
                    }
                    setShowSkeleton(false)
                };
                await fetchData();
                setShowSkeleton(false)
            }
        });
        // Cleanup the observer when the component is unmounted
        return () => {
            unregisterAuthObserver();
        }
    }, []);

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    const handleRdvValidated = async (notification, indexRem) => {

        toggleLoadingState(indexRem);

        if (new Date(notification.start.seconds * 1000) < new Date()){
            handleOpenSnackbar("Ce rdv est déjà passé");
            return;
        }

        const dbObj = {
            timestamp : new Date(),
            isValidated : true
        }

        try {
            await validateRdvById(dbObj, notification.documentId);
        } catch (e) {
            handleOpenSnackbar("Quelque chose s'est mal passé, contactez nous.");
            setShowBackDrop(false);
            return;
        }

        toggleLoadingState(indexRem);
        setIsValidatedState(indexRem);

    };

    const handleRdvDiscarded = (notif, index) => {
        setCurrentNotif(notif);
        setCurrentIndex(index);
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const handleCancelSelection = (option) => {
        setSelectedCancel(option);
    }

    const navigateToCancel = async () => {
        setIsDialogOpen(false);
        toggleLoadingState(index);

        if (selectedCancel.length<2){
            handleOpenSnackbar("Vous devez séléctionner un motif.")
            return;
        }

        const updateIsCanceled = {
            isCanceled : true,
            cancelReason : selectedCancel,
            cancelPerson : userData.pseudo,
            isAlreadyRefunded : isAlreadyRefunded,
            timestamp : new Date()
        }

        await cancelRdvById(updateIsCanceled, currentNotif.documentId);

        if (currentNotif.isGoogle){
            const today = new Date(currentNotif.start.seconds * 1000);
            const tomorrow = new Date(currentNotif.end.seconds * 1000);
            today.setDate(today.getDate()-1);
            tomorrow.setDate(tomorrow.getDate() + 1);
            const canceledGoogleEvent = await cancelEvent({
                uidFromCall : currentNotif.uid,
                eventId : Array.from(new TextEncoder().encode(String(currentNotif.documentId)))
                    .map(byte => (byte < 10 ? '0' : '') + byte.toString(32))
                    .join('')
                    .toLowerCase(),
                today : today.toISOString(),
                tomorrow : tomorrow.toISOString()
            })
            /*
            if (canceledGoogleEvent.data){
                setHasGoogleBeenRemoved(true);
            }
            */
        }

        toggleLoadingState(index);
        setIsDiscardedState(index);

        //todo remove notification from array if all went well - no refresh

        /*To be replaced soon
        await navigate("/appointcancel/"+rdvObj.documentId, {
            state : {
                cancelReason : selectedCancel,
                cancelPerson : userData.pseudo
            }
        });*/
    }

    const [loadingStates, setLoadingStates] = useState(new Array(notifications.length).fill(false));
    const [isValidatedStates, setIsValidatedStates] = useState(new Array(notifications.length).fill(false));
    const [isDicardedStates, setIsDiscardedStates] = useState(new Array(notifications.length).fill(false));

    // Function to toggle the loading state for an element
    const toggleLoadingState = (index) => {
        setLoadingStates(prevStates => {
            const newState = [...prevStates];
            newState[index] = !newState[index];
            return newState;
        });
    };

    const setIsValidatedState= (index) => {
        setIsValidatedStates(prevStates => {
            const newState = [...prevStates];
            newState[index] = true;
            return newState;
        });
    };

    const setIsDiscardedState= (index) => {
        setIsDiscardedStates(prevStates => {
            const newState = [...prevStates];
            newState[index] = true;
            return newState;
        });
    };


    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            {showSkeleton ?
                (
                    <div className="sub-container-no-top">
                        <div style={{
                            display: "flex", width: "100%", flexDirection: "column",
                            justifyContent: "center", alignContent: "center", alignItems: "center"
                        }}>
                            <Skeleton style={{borderRadius: "15px"}} variant="text" sx={{fontSize: '2rem'}}
                                      width="70%"/>
                            <Skeleton style={{marginTop: "50px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                            <Skeleton style={{marginTop: "25px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                            <Skeleton style={{marginTop: "25px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                        </div>
                    </div>
                ) : (
                    <div className="sub-container-no-top">
                        <span className="title-style" style={{textAlign:"center"}}>Demandes de réservation</span>
                        <span style={{marginTop:"36px", fontWeight:400, fontSize:"20px"}}>
                            Vous avez <span style={{fontWeight:700, fontSize:"20px"}}>{notifications?.length<2 ? notifications?.length + " demande" : notifications?.length + " demandes"}</span> en attente
                        </span>
                        {notifications.map((notification, index) => (
                            <div key={index} style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start",
                                gap: "15px",borderRadius: "15px", background: "#FFF", boxShadow: "0px 4px 15px 0px rgba(218, 224, 229, 0.90)", width:"100%", marginTop:"36px", marginBottom:"70px"
                            }}>
                                <div style={{position:"relative", width:"100%"}}>
                                    {loadingStates[index] && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                zIndex: (theme) => theme.zIndex.drawer + 1,
                                                color: "#C200FB",
                                                position: 'absolute',
                                                top: 8,
                                                right: 8
                                            }}
                                        />
                                    )}
                                </div>
                                <div style={{margin:"20px 30px", display:'flex', flexDirection:"column"}}>


                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            marginBottom:"25px"
                                        }}
                                    >
                                        <div style={{ width: "10%", alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex" }}>
                                            <img onLoad={handleImageLoad}
                                                 className="fade-in" style={{color:"white", borderRadius:"5px", width:"30px",height:"30px", objectFit:"cover"}}
                                                 src={notification?.prestaSnapshot?.imageLinks?.length> 0 ? notification?.prestaSnapshot?.imageLinks[0]?.imageLink : "https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA.." }/>
                                        </div>
                                        <div
                                            style={{
                                                width: "90%", // Adjusted width to make space for the close icon
                                                marginLeft: "15px",
                                            }}
                                        >
                                            <span style={{color:"black", fontWeight:700, fontSize:"16px"}}>{notification?.prestaSnapshot.title}</span>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            marginBottom:"25px"
                                        }}
                                    >
                                        <div style={{ width: "10%", alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex" }}>
                                            <img onLoad={handleImageLoad}
                                                 className="fade-in"
                                                 src={checkGreyIc}/>
                                        </div>
                                        <div
                                            style={{
                                                width: "90%", // Adjusted width to make space for the close icon
                                                marginLeft: "15px",
                                            }}
                                        >
                                            <span style={{color:"black", fontWeight:400, fontSize:"16px",overflowWrap: "break-word", whiteSpace:"normal"}}>{formatDate(convertToTimeZone(new Date(notification.start.seconds * 1000)))} à {formatHour(convertToTimeZone(new Date(notification.start.seconds * 1000)))}</span>
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div style={{ width: "10%", alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex" }}>
                                            <img onLoad={handleImageLoad}
                                                 className="fade-in"
                                                 src={userGreyIc}/>
                                        </div>
                                        <div
                                            style={{
                                                width: "90%", // Adjusted width to make space for the close icon
                                                marginLeft: "15px",
                                            }}
                                        >
                                            <span style={{color:"black", fontWeight:400, fontSize:"16px"}}>{notification.custAddress}</span>
                                        </div>
                                    </div>
                                    {isValidatedStates[index] ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row", // Change to row to have icon and content side by side
                                                alignItems: "center",
                                                marginTop:"15px"
                                            }}
                                        >
                                            <div style={{
                                                width: "10%",
                                                alignItems: "center",
                                                alignContent: "center",
                                                justifyContent: "center",
                                                display: "flex"
                                            }}>
                                               <img src={checkGreenIc} style={{width: "30px", height: "30px"}}/>
                                            </div>
                                            <div
                                                style={{
                                                    marginLeft: "10px",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignContent: "center",
                                                    justifyContent: "center"
                                                }}
                                            >
                                                <span style={{color: "#06AC2C", fontWeight:700}}>Réservation validée</span>
                                                <span style={{marginLeft:"10px", color: "#4808FF", cursor:"pointer",
                                                    textDecorationLine: "underline"}}  onClick={()=>navigate('/rdvdetails/'+notification?.documentId)}>Plus</span>
                                            </div>
                                        </div>
                                    ) : (
                                        isDicardedStates[index] ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row", // Change to row to have icon and content side by side
                                                    alignItems: "center",
                                                    marginTop:"15px"
                                                }}
                                            >
                                                <div style={{
                                                    width: "10%",
                                                    alignItems: "center",
                                                    alignContent: "center",
                                                    justifyContent: "center",
                                                    display: "flex",
                                                }}>
                                                    <img src={icCancel} style={{width: "30px", height: "30px"}}/>
                                                </div>
                                                <div
                                                    style={{
                                                        marginLeft: "10px",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignContent: "center",
                                                        justifyContent: "center"
                                                    }}
                                                >
                                                    <span style={{color: "#FF0844", fontWeight:700}}>Réservation annulée</span>
                                                    <span style={{marginLeft:"10px", color: "#4808FF", cursor:"pointer",
                                                        textDecorationLine: "underline"}}  onClick={()=>navigate('/rdvdetails/'+notification?.documentId)}>Plus</span>
                                                </div>
                                            </div>
                                            ) :(
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row", // Change to row to have icon and content side by side
                                                        alignItems: "center", // Center icon and content vertically
                                                        paddingTop: "15px",
                                                        paddingBottom: "12px",
                                                        width:"100%"
                                                    }}
                                                >
                                                    <Button disabled={loadingStates[index]} onClick={()=>handleRdvValidated(notification, index)} disableElevation component="label" className="button-style-noborders-lil" variant="contained" style={{background:"linear-gradient(90deg, #FF0844 0%, #4808FF 92.63%)",
                                                        color:"white"}}>
                                                        <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px", lineHeight:"16px" }}>
                                                            Valider
                                                        </Typography>
                                                    </Button>
                                                    <Button disabled={loadingStates[index]} onClick={()=>handleRdvDiscarded(notification, index)} disableElevation component="label" className="button-style-noborders-lil" variant="contained" style={{background:"white",
                                                        color:"black", border:"1px solid black", marginLeft:"10px"}}>
                                                        <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px", lineHeight:"16px" }}>
                                                            Refuser
                                                        </Typography>
                                                    </Button>
                                                    <span style={{marginLeft:"10px", color: "#4808FF", cursor:"pointer",
                                                        textDecorationLine: "underline"}}  onClick={()=>navigate('/rdvdetails/'+notification?.documentId)}>Plus</span>

                                                </div>
                                        )

                                    )}


                                </div>
                            </div>
                        ))}

                    </div>
                )
            }

            <Dialog open={isDialogOpen} onClose={handleDialogClose}
                    fullWidth={!isLargeScreen}
                    PaperProps={{
                        style: {
                            minWidth: isLargeScreen ? '500px' : 'auto',
                            maxWidth: isLargeScreen ? '500px' : 'auto'
                        },
                    }}>
                <DialogContent style={{backgroundColor:"white"}}>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                        <span style={{fontSize:"16px", fontWeight:700, marginTop:"30px"}}>Votre motif d'annulation</span>
                        <div style={{display:"flex", width:"100%", flexDirection:"column", paddingTop:"30px", paddingBottom:"20px"}}>
                            {cancelOptions.map((option, index) => (
                                <MenuItem onClick={()=> (handleCancelSelection(option))}
                                          style={{
                                              zIndex:1000,
                                              background:selectedCancel===option
                                                  ? "linear-gradient(150deg, #4808FF 0%, #9747FF 100%)"
                                                  : "#DAE0E5",
                                              marginBottom: "10px",
                                              padding: "10px 15px",
                                              borderRadius:"15px"
                                          }}
                                          key={index}
                                >
                                                <span style={{width: "100%",
                                                    overflow: "hidden",
                                                    color:selectedCancel===option
                                                        ? "white"
                                                        : "black",
                                                    fontWeight:selectedCancel===option
                                                        ? 700
                                                        : 400,
                                                    fontSize:"16px",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis"}}>
                                                    {option}
                                                </span>
                                </MenuItem>
                            ))}

                        </div>
                    </div>
                </DialogContent>
                <DialogActions style={{backgroundColor:"white"}}>
                    <div className="button-container" style={{marginBottom:"30px",marginTop:"6px", marginLeft:"30px", marginRight:"30px"}}>
                        <Button onClick={()=>navigateToCancel()} className="button-style button-style-noborders" variant="contained">
                            <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                Enregistrer
                            </Typography>
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showBackDrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar}
                                          message={snackMessage} style={{width: "100%"}}/>
        </div>
    )

}