import React, {useEffect, useState} from "react";
import {Backdrop, CircularProgress, Skeleton, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {useLocation, useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import firebase from "firebase/compat/app";
import 'firebase/firestore'
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import {getUser, getUserSubscription, updateRdv} from "../firebase";
import SnackbarWithCustomBackground from "./snackbar";
import {serverTimestamp} from "@firebase/firestore";
import userlogo from "../assets/rec.png";
import {v4} from "uuid"
import pinMapBlue from "../assets/ph_map-pin_blue.png";
import calPlusBlue from "../assets/ph_calendar-check_blue.png";
import {HeaderHome} from "./headerHome";
import {DateTime} from "luxon";
import ReactGA from "react-ga4";
import {addDurationToTime, parseFrenchDateWithTimezone} from "./utils";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css'
import parsePhoneNumberFromString from "libphonenumber-js";
import fr from 'react-phone-input-2/lang/fr.json';

const validateEmail = (email) => {
    // Basic email validation using a regular expression
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
};

function isValidFrenchPhoneNumber(phoneNumber) {
    // Remove any non-digit characters from the input
    const cleanNumber = phoneNumber.replace(/\D/g, '');

    // Check if the number starts with "33" or "0" followed by nine digits
    const isValidFormat = /^(33|0\d{9})$/.test(cleanNumber);

    if (isValidFormat) {
        // If the number starts with "33", replace it with "0"
        const normalizedNumber = cleanNumber.startsWith('33') ? `0${cleanNumber.slice(2)}` : cleanNumber;
        return { isValid: true, phoneNumber: normalizedNumber };
    } else {
        return { isValid: false, phoneNumber: null };
    }
}

export default function AppointConfirm() {
    //firebase.functions().useEmulator("127.0.0.1", 5001);
    const location = useLocation();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const queryParams = new URLSearchParams(location.search);
    const createCalendarEventFunction = firebase.functions().httpsCallable('createCalendarEvent');

    /*const prestaObjectStr = queryParams.get('prestaObject');
    const agendaObjectStr = queryParams.get('agendaObject');
    const selectedItemStr = queryParams.get('selectedItem');
    const selectedDateStr = queryParams.get('date');


    let prestaObject = JSON.parse(decodeURIComponent(prestaObjectStr));
    const agendaObject = JSON.parse(decodeURIComponent(agendaObjectStr));
    const selectedTime = JSON.parse(decodeURIComponent(selectedItemStr));
    const selectedDate = JSON.parse(decodeURIComponent(selectedDateStr));
    const prestaObjectNoDecode = JSON.parse(prestaObjectStr);

    if (prestaObjectNoDecode.imageLinks.length>0){
        const urls = prestaObjectNoDecode.imageLinks;
        prestaObject = {
            ...prestaObject,
            imageLinks : urls
        }
    }*/
    const prestaObject = location?.state?.prestaObject;
    const agendaObject = location?.state?.agendaObject;
    const selectedTime = location?.state?.selectedItem;
    const selectedDate = location?.state?.date;
    const isAllSlots = location?.state?.isAllSlots;

    const [formData, setFormData] = useState({
        email : "", nom : "", phone : "", address : []
    });
    const [userObject, setUserObject] = useState({});
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [subStatus, setSubStatus] = useState("");
    const [rdvLeft, setRdvLeft] = useState(0);
    const updateRdvPresta = firebase.functions().httpsCallable('updateRdvPresta');
    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect( () => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged( async(user) => {
            setShowSkeleton(true);
            if (!user) {
                setShowBackdrop(false);
                navigate("/");
            } else {
                //warmup
                updateRdvPresta({isWarmUp:true}).catch((e)=>console.log(e));
                const fetchData = async () => {
                    //firebase.functions().useEmulator("127.0.0.1", 5001);
                    try {
                        const subObj = await getUserSubscription(user.uid);
                        if (subObj?.status){
                            setSubStatus(subObj.status);
                            setRdvLeft(50 - subObj.count);
                        } else {
                            setSubStatus("free");
                            setRdvLeft(0);
                        }
                        const userData = await getUser(user?.uid);
                        setUserObject(userData)
                        setShowSkeleton(false)
                    } catch (error) {
                        console.error('Error calling function:', error.message);
                        setShowSkeleton(false);
                    }
                };
                await fetchData();
                setShowSkeleton(false);
            }
            setShowSkeleton(false);
        });
        // Cleanup the observer when the component is unmounted
        return () => {
            unregisterAuthObserver();
        }
    }, []);

    const handleFormChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handlePhoneChange = (phone, country) => {
        setFormData({
            ...formData,
            phone: phone
        });
    };

    const navigateBack = async () => {
        navigate(-1);
    }

    const navigateHome = async () => {
        navigate("/home");
    }

    const navigateToSuccess = async (docId, start, end, uidv4) => {
        /*navigate("/appointsuccess", {
            state : {
                displayDate :  selectedDate + " " + selectedTime,
                address : prestaObject.selectedAddress || "",
                custName : formData.nom,
                custAddress : formData.email,
                custPhone : formData.phone,
                title : prestaObject.title,
                price : prestaObject.price,
                duration : prestaObject.prestaDuration,
                images : prestaObject.imageLinks,
                description : prestaObject.description,
                rdvDocId : docId,
                isGoogle : agendaObject.googleAGCode,
                start : start,
                end : end,
                uidv4 : uidv4,
                pseudo : userObject.pseudo
            },
            replace: true
        });*/
        navigate("/rdvdetails/"+docId);
    }

    const handleConfirm = async () => {
        if (rdvLeft>0 || (subStatus==="trialing" || subStatus==="active")){
            setShowBackdrop(true);
            if (formData.nom.length<2){
                handleOpenSnackbar("Vous devez entrer le nom de votre client");
                setShowBackdrop(false);
                return;
            } else if (!validateEmail(formData.email)){
                handleOpenSnackbar("L'adresse email de votre client ne semble pas valide, vérifiez la.");
                setShowBackdrop(false);
                return;
            }

            if (formData.phone.length>3){
                /*const phoneObj = isValidFrenchPhoneNumber(formData.phone);
                if (!phoneObj.isValid){
                    setShowBackdrop(false);
                    handleOpenSnackbar("Le numéro de téléphone n'est pas français ou pas valide, vérifiez le.");
                    return;
                }*/
                const phoneNumber = parsePhoneNumberFromString(formData.phone[0] === '+' ? formData.phone : `+${formData.phone}`);
                if (!(phoneNumber && phoneNumber.isValid())) {
                    handleOpenSnackbar("Le numéro de téléphone n'est pas valide ou pas au bon format, vérifiez le ou laissez le champ vide.");
                    setShowBackdrop(false);
                    return;
                }
            }
            const startDateStr = selectedDate + " " + selectedTime;
            const [date,[day,month,year]] = parseFrenchDateWithTimezone(startDateStr, userObject?.timezone);

            let endDateClient;
            let endDate;
            try {
                endDateClient = addDurationToTime(prestaObject.prestaDuration,date);
                endDate = addDurationToTime(prestaObject.breakDuration,endDateClient);
            } catch (e){
                handleOpenSnackbar("Quelque chose s'est mal passé, recommencez ou contactez nous.")
                console.log(e.message);
                return;
            }
            const uidv4 = v4();
            let dbObject = {
                dateStr : startDateStr,
                timeStr : selectedTime,
                prestaId : prestaObject.documentId,
                uid : prestaObject.uid,
                start : date,
                end : endDate,
                endDateClient: endDateClient,
                isCanceled : false,
                day : day,
                month : month,
                year : year,
                timestamp : serverTimestamp(),
                prestaSnapshot : prestaObject,
                custName : formData.nom,
                custAddress : formData.email,
                custAddressNormalized : formData.email.toLowerCase(),
                custPhone: formData.phone.length>3 ? "+"+formData.phone : "",
                isCustPhoneValid: formData.phone.length>3,
                cancelId: uidv4,
                isGoogle : agendaObject.googleAGCode,
                isValidated : true
            }
            let docId = ""
            try {
                docId = await updateRdv(dbObject, isAllSlots);
                if (docId==="blocked"){
                    handleOpenSnackbar("Ce créneau a été réservé à l'instant, essayez de choisir un autre créneau.");
                    setShowBackdrop(false);
                    return;
                }

            } catch (e){
                console.log("Error => ", e.message);
                handleOpenSnackbar("Quelque chose s'est mal passé, contactez nous.");
                setShowBackdrop(false);
                return;
            }
            ReactGA.event({
                category: "rdvpresta",
                action: "validate",
                value: 1,
                nonInteraction: true,
                transport: "xhr",
            });
            if (agendaObject.googleAGCode){
                try {
                    //needs to be base32 encoded to be added as calendarId - this allow binding to "rdv" collection

                    const encodedId = Array.from(new TextEncoder().encode(String(docId)))
                        .map(byte => (byte < 10 ? '0' : '') + byte.toString(32))
                        .join('')
                        .toLowerCase();
                    // That is a way to decode, case preserved, for later use
                    /*
                    const decodedId = new TextDecoder().decode(
                         new Uint8Array(encodedId.match(/.{1,2}/g).map(chunk => parseInt(chunk, 32)))
                     );
                     console.log("decoded => ", decodedId);*/

                    await createCalendarEventFunction({
                        summary: prestaObject.title,
                        startDateTime: date.toISOString(),
                        endDateTime: endDateClient.toISOString(),
                        docId : encodedId.toLowerCase(),
                        description: "Client : " + formData.nom + "\n" +
                            "Email : " + formData.email + "\n" +
                            "Téléphone : " + (formData.phone.length>3 ? "+"+formData.phone : "") + "\n" +
                            "Page de réservation : https://my.faismoibg.com/rdvdetails/" + docId + " \n" +
                            "Prix : " + prestaObject?.price,
                    })
                } catch (e) {
                    handleOpenSnackbar("RDV créé avec succès, mais échec lors de la synchronisation google");
                    console.log(e.message);
                }
            }
            setShowBackdrop(false);
            await navigateToSuccess(docId, date, endDateClient, uidv4);
        } else {
            handleOpenSnackbar("Il ne vous reste plus de rendez-vous pour ce mois. Passez en forfait premium, ou attendez le mois prochain.")
        }
    }

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };


    if (!prestaObject || !agendaObject || !selectedTime || !selectedDate) {
        return (
            <div className="sub-container">
                <HeaderHome></HeaderHome>
                <div className="sub-container-no-top">
                    <div style={{display: "flex", width:"80%", padding: "20px", flexDirection: "column", justifyContent: "center", marginTop:"30px",
                        alignItems: "center", borderRadius: "15px", background: "#FFF", boxShadow:'0px 4px 15px 0px rgba(218, 224, 229, 0.90)'}}>
                                        <span style={{lineHeight: "24px"}}>
                                            Cette page n'existe pas ou a expirée 🤷
                                        </span>
                    </div>
                </div>
                <div className="button-container" style={{marginTop:"60px"}}>
                    <Button className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateHome}>
                        <Typography variant="button" style={{ textTransform: 'none' }}>
                            Retour
                        </Typography>
                    </Button>
                </div>
            </div>

        )
    }

    //todo add conditional statements to handle no images cases

    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            {showSkeleton ?
                (
                    <div className="sub-container-no-top">
                        <div style={{display:"flex", width:"100%", flexDirection:"column",
                            justifyContent:"center", alignContent:"center", alignItems:"center"}}>
                            <Skeleton style={{borderRadius:"15px"}} variant="text" sx={{ fontSize: '2rem' }} width="70%" />
                            <Skeleton style={{marginTop:"50px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                            <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                            <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        </div>
                    </div>
                ) : (

            <div className="sub-container-no-top">
                <span className="title-style" style={{textAlign:"center"}}>Renseignez les coordonnées de votre client</span>
                <span style={{textAlign:"center", width:"100%", marginTop:"28px", fontSize:"20px"}}>
                Nous avons besoin de l’email et/ou du numéro de téléphone de votre client afin de lui envoyer une confirmation de rendez-vous ainsi qu’une notification de rappel J-1 avant son rdv.
                </span>
                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Nom <span className="aster">*</span>
                                  </span>
                    </div>
                    <TextField onChange={handleFormChange} value={formData?.nom} className="field-style"
                               label="Ecrire" variant="outlined" name="nom" />
                </div>

                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Email <span className="aster">*</span>
                                  </span>
                    </div>
                    <TextField onChange={handleFormChange} value={formData.email} className="field-style"
                               label="Ecrire" variant="outlined" name="email" />
                </div>

                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Téléphone (format international)
                                  </span>
                    </div>
                    <PhoneInput
                        country={'fr'} // Set your default country
                        value={formData?.phone}
                        onChange={(phone,details)=>handlePhoneChange(phone, details)}
                        specialLabel=""
                        countryCodeEditable={false}
                        localization={fr}
                    />




                    {/*<TextField onChange={handleFormChange} value={formData?.phone} className="field-style"
                               label="Ecrire" variant="outlined" name="phone" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}/>*/}
                </div>

                <div
                    style={{
                        display: "flex",
                        background: "linear-gradient(180deg, #4808FF 0%, #9747FF 100%)",
                        marginTop: "50px",
                        minHeight: "10px",
                        borderRadius: "15px",
                        boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.25)",
                        width: "100%",
                        flexDirection: "column", // Change to column to stack items vertically
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop:"25px", marginRight:"5px" }}>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row", // Change to row to have icon and content side by side
                            alignItems: "center", // Center icon and content vertically
                            padding: "1px 20px", // Add padding to create some space around the content
                            marginBottom:"25px"
                        }}
                    >
                        <div style={{ width: "10%",alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex" }}>
                            <img src={calPlusBlue} style={{width:"30px", height:"30px"}}/>
                        </div>
                        <div
                            style={{
                                width: "90%", // Adjusted width to make space for the close icon
                                marginLeft: "10px",
                            }}
                        >
                            <span style={{color:"white"}}>{selectedDate} à {selectedTime}</span>
                        </div>
                    </div>
                    {prestaObject?.selectedAddress?.length>1 ?
                        (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row", // Change to row to have icon and content side by side
                                    alignItems: "center", // Center icon and content vertically
                                    padding: "1px 20px", // Add padding to create some space around the content
                                    marginBottom:"25px"
                                }}
                            >
                                <div style={{ width: "10%",alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex"  }}>
                                    <img src={pinMapBlue} style={{width:"30px", height:"30px"}}/>
                                </div>
                                <div
                                    style={{
                                        width: "90%", // Adjusted width to make space for the close icon
                                        marginLeft: "10px",
                                    }}
                                >
                                    <span style={{color:"white"}}>{prestaObject.selectedAddress}</span>
                                </div>
                            </div>
                        ) :
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row", // Change to row to have icon and content side by side
                                alignItems: "center", // Center icon and content vertically
                                padding: "1px 20px", // Add padding to create some space around the content
                                marginBottom:"25px"
                            }}
                        >
                            <div style={{ width: "10%",alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex"  }}>
                                <img src={pinMapBlue} style={{width:"30px", height:"30px"}}/>
                            </div>
                            <div
                                style={{
                                    width: "90%", // Adjusted width to make space for the close icon
                                    marginLeft: "10px",
                                }}
                            >
                                <span style={{color:"white"}}>Vous n'avez pas séléctionné d'adresse pour cette prestation</span>
                            </div>
                        </div>

                    }


                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row", // Change to row to have icon and content side by side
                            alignItems: "center", // Center icon and content vertically
                            padding: "1px 20px", // Add padding to create some space around the content
                            marginBottom:"25px"
                        }}
                    >
                        <div style={{ width: "10%", alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex" }}>
                            <img onLoad={handleImageLoad}
                                 className="fade-in" style={{color:"white", borderRadius:"50%", width:"30px",height:"30px", objectFit:"cover"}}
                                 src={userObject.imageUrl.length> 0 ? userObject.imageUrl : userlogo }/>
                        </div>
                        <div
                            style={{
                                width: "90%", // Adjusted width to make space for the close icon
                                marginLeft: "10px",
                            }}
                        >
                            <span style={{color:"white"}}>{userObject.pseudo}</span>
                        </div>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row", // Change to row to have icon and content side by side
                            alignItems: "center", // Center icon and content vertically
                            padding: "1px 20px", // Add padding to create some space around the content
                            marginBottom:"25px"
                        }}
                    >
                        <div style={{ width: "10%", alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex" }}>
                            <img onLoad={handleImageLoad}
                                 className="fade-in" style={{color:"white", borderRadius:"5px", width:"30px",height:"30px", objectFit:"cover"}}
                                 src={prestaObject.imageLinks.length> 0 ? prestaObject.imageLinks[0].imageLink : "https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA.." }/>
                        </div>
                        <div
                            style={{
                                width: "90%", // Adjusted width to make space for the close icon
                                marginLeft: "10px",
                            }}
                        >
                            <span style={{color:"white", fontWeight:700}}>{prestaObject.title}</span>
                        </div>
                    </div>

                </div>

                <div className="button-container" style={{marginTop:"80px"}}>
                    <Button onClick={handleConfirm} className="button-style button-style-noborders" variant="contained">
                        <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                            Confirmer la réservation
                        </Typography>
                    </Button>
                </div>

                <div className="button-container" style={{marginTop:"15px"}}>
                    <Button style={{marginBottom:"100px"}} className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateBack}>
                        <Typography variant="button" style={{ textTransform: 'none' }}>
                            Retour
                        </Typography>
                    </Button>
                </div>


                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={showBackdrop}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />
            </div>)}

        </div>
    )
}