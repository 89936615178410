import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import Button from "@mui/material/Button";
import {formatHour,convertToTimeZone} from './utils';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Backdrop,
    Chip, CircularProgress,
    InputAdornment,
    Skeleton,
    TextField,
    Typography
} from "@mui/material";
import firebase from "firebase/compat/app";
import {HeaderHome} from "./headerHome";
import icDiamond from "../assets/diamondColored.svg";
import {
    getBlackListDoc,
    getClientsRdv,
    getCommentDoc,
    getCustomers,
    getUserBlocs,
    getUserSubscription,
    updateBlackList, updateComment
} from "../firebase";
import SnackbarWithCustomBackground from "./snackbar";
import {FaSearch} from "react-icons/fa";
import userCircleGreyIc from "../assets/userGreyCircleIc.svg";
import expandMoreIc from "../assets/expandMoreIc.svg";
import calendarCheckGreyIc from "../assets/calendarCheckGrey.svg";
import {useParams} from "react-router";
import dayjs from "dayjs";
import {IOSSwitch} from "./iosswitch";
import timeCheckGrey from "../assets/timeCheckGrey.svg";
import pinMapGrey from "../assets/ph_map-pin.svg";
import timeTableOrangeIc from "../assets/icTimeTableGreen.svg";
import checkGreenIc from "../assets/ic_check.svg";
import dollarIc from "../assets/dollaIc.svg";
const faStatusStyle = {
    position: 'absolute',
    top: '8px',
    right: '8px',
    opacity:'100%',
    zIndex: 1,
};
export default function ViewClient() {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const { cid } = useParams();
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [isSub, setIsSub] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [items, setItems] = useState([]);
    const [formData, setFormData] = useState({
        isBlackList : false, comment : "",
    });
    const [timeZoneState, setTimeZoneState] = useState("Europe/Paris");
    const fallBackUrl = "https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA..";
    const realDate = new Date();
    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(async (user) => {
            if (!user) {
                navigate("/");
            } else {
                const fetchData = async () => {
                    try {
                        const subObj = await getUserSubscription(user.uid);
                        if (subObj?.status==="trialing" || subObj?.status==="active"){
                            setIsSub(true);
                            const resp = await getClientsRdv(atob(cid), user.uid);
                            if (resp.length>0) setItems(resp.sort((a,b)=>b.start.seconds-a.start.seconds));
                            const commentDoc = await getCommentDoc(cid, user.uid);
                            const blackListDoc = await getBlackListDoc(user.uid);
                            if (commentDoc) {
                                setFormData(prev => ({
                                    ...prev,
                                    comment: commentDoc.comment
                                }));
                            }

                            if (blackListDoc && blackListDoc.blacklist.some(email => email === atob(cid))) {
                                setFormData(prev => ({
                                    ...prev,
                                    isBlackList: true
                                }));
                            }
                            setShowSkeleton(false);
                        } else {
                            setIsSub(false);
                            setShowSkeleton(false);
                        }
                    } catch (e) {
                        console.error(e);
                        handleOpenSnackbar("Quelque chose s'est mal passé, actualisez la page ou contactez nous.");
                        setShowSkeleton(false);
                    }
                }
                fetchData();
            }
        });
        // Cleanup the observer when the component is unmounted
        return () => {
            unregisterAuthObserver();
        }
    }, []);

    const handleFormCommentChange = (event) => {
        const newText = event.target.value.replace(/\r?\n/g, '\n');
        setFormData({
            ...formData,
            [event.target.name]: newText
        });
    };

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };
    const navigateHome = () => {
        navigate(-1);
    };

    const navigateToRdvDetails = (index) => {
        navigate("/rdvdetails/" + items[index].documentId)
    };
    const handleBlackListChanged = (event) => {
        setFormData({
            ...formData,
            isBlackList:!formData.isBlackList,
        })
    };

    const handleFormSubmit = async () => {
        setShowBackdrop(true);
        try {
            await updateBlackList(cid, formData.isBlackList, firebase.auth()?.currentUser?.uid);
            await updateComment(cid, formData.comment, firebase.auth()?.currentUser?.uid);
            handleOpenSnackbar("Changements enregistrés avec succès");
            setShowBackdrop(false);
        } catch (e) {
            handleOpenSnackbar("Quelque chose s'est mal passé, réessayez, ou contactez nous. "+e.message);
            console.error(e);
            setShowBackdrop(false);
        }
    }

    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            {showSkeleton ?
                (
                    <div className="sub-container-no-top">
                        <div style={{
                            display: "flex", width: "100%", flexDirection: "column",
                            justifyContent: "center", alignContent: "center", alignItems: "center"
                        }}>
                            <Skeleton style={{borderRadius: "15px"}} variant="text" sx={{fontSize: '2rem'}}
                                      width="70%"/>
                            <Skeleton style={{marginTop: "50px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                            <Skeleton style={{marginTop: "25px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                            <Skeleton style={{marginTop: "25px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                        </div>
                    </div>
                ) : (

                    isSub ? (
                        <div className="sub-container-no-top">
                            <span className="title-style">{atob(cid)}</span>
                            <div className="field-container" style={{marginTop:"30px", display:"flex", flexDirection:"row", alignContent:"top", alignItems:"top"}} >
                                <div className="title-field-container" style={{width:"70%"}}>
                                          <span className="text-field-style">
                                              Nom, prénom ou pseudo utilisé
                                          </span>
                                </div>
                            </div>
                            <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
                                <div style={{display:"flex", flexDirection:"row", justifyContent:"start", alignContent:"center", alignItems:"center",margin:"20px 30px"}}>
                                    <img src={userCircleGreyIc}/>
                                    <span style={{marginLeft:"12px", overflowWrap:"break-word", width:"85%"}}>{items[0]?.custName || ""}</span>
                                </div>
                            </div>
                            <div className="field-container-b" style={{marginTop:"30px"}}>
                                <div className="title-field-container">
                                  <span className="text-field-style">
                                      Commentaire
                                  </span>
                                </div>
                                <TextField className="field-style" variant="outlined" value={formData.comment}
                                           multiline
                                           maxRows={12}
                                           label="Ecrire"
                                           onChange={handleFormCommentChange} name="comment"/>
                            </div>
                            <div className="field-container">
                                <div className="title-field-container">
                                  <span className="text-field-style">
                                      Blacklist
                                  </span>
                                </div>
                            </div>
                            <div style={{ marginTop:"10px" ,width:"100%", display:"flex", flexDirection:"row", justifyContent:"start", alignItems:"center"}}>

                                <IOSSwitch
                                    checked={formData.isBlackList}
                                    onChange={(event) => handleBlackListChanged(event)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />

                                {formData.isBlackList ? <span style={{marginLeft:"20px", color:"black"}}>Activé</span>
                                    : <span style={{marginLeft:"20px", color:"black"}}>Desactivé</span>}

                            </div>
                            <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
                                <Accordion
                                    style={{marginTop:"30px"}}
                                    sx={{
                                        boxShadow: 'none',         // Removes the box-shadow (elevation)
                                        '&:before': { display: 'none' } // Hides the pseudo-element that creates the border line
                                    }}
                                >
                                    <AccordionSummary
                                        sx={{ p: 0 }}
                                        expandIcon={<img alt="expand more icon" src={expandMoreIc} />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography style={{fontSize:"16px"}} component="span">Prestations ({items?.length?.toString()})</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails
                                        sx={{ display: "flex",p: 0 }}>
                                        <div style={{ maxHeight: '600px', overflowY: 'scroll', padding:"12px", width:"100%"}}>
                                            {items.sort((a, b) => b.start.seconds - a.start.seconds).map((item, index) => (
                                                <div key={index+1} onClick={() => navigateToRdvDetails(index)}
                                                     style={{
                                                         display: "flex",
                                                         background: item?.start?.toDate() > realDate ? "#FFF" : "#F5F5F5",
                                                         /*opacity: item?.start?.toDate() > realDate ? "100%" : 0.7,
                                                         WebkitOpacity: item?.start?.toDate() > realDate ? "100%" : 0.7,
                                                         MozOpacity: item?.start?.toDate() > realDate ? "100%" : 0.7,*/
                                                         cursor: item?.start?.toDate() > realDate ? "pointer" : "pointer",
                                                         marginTop: "23px",
                                                         minHeight: "10px",
                                                         borderRadius: "15px",
                                                         boxShadow: "0px 2px 11px 0px rgba(0, 0, 0, 0.25)",
                                                         width: "100%",
                                                         flexDirection: "column",
                                                     }}
                                                >
                                                    <div style={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        marginTop: "25px",
                                                        marginRight: "5px"
                                                    }}>
                                                    </div>
                                                    <div
                                                        style={{
                                                            color: "inherit",
                                                            display: "flex",
                                                            flexDirection: "row", // Change to row to have icon and content side by side
                                                            alignItems: "center", // Center icon and content vertically
                                                            padding: "1px 20px", // Add padding to create some space around the content
                                                            marginBottom: "15px"
                                                        }}
                                                    >
                                                        <div style={{
                                                            width: "30%",
                                                            alignItems: "center",
                                                            alignContent: "center",
                                                            justifyContent: "center",
                                                            display: "flex"
                                                        }}>
                                                            <img onLoad={handleImageLoad}
                                                                 onError={(e) => {
                                                                     if (e.target.src!==fallBackUrl){
                                                                         e.target.onerror = null;
                                                                         e.target.src= fallBackUrl
                                                                     }
                                                                 }}
                                                                 className="fade-in" style={{
                                                                color: "white",
                                                                borderRadius: "5px",
                                                                width: "60px",
                                                                height: "60px",
                                                                objectFit: "cover",
                                                            }}
                                                                 src={item?.prestaSnapshot?.imageLinks?.length > 0 ? item?.prestaSnapshot?.imageLinks[0]?.imageLink : "https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA.."}/>
                                                        </div>
                                                        <div
                                                            style={{
                                                                width: "70%", // Adjusted width to make space for the close icon
                                                                marginLeft: "10px",
                                                                marginRight: "50px",
                                                            }}
                                                        >
                                            <span style={{
                                                color: "black",
                                                fontWeight: "700",
                                                fontSize: "16px"
                                            }}>{item.prestaSnapshot.title}</span>
                                                        </div>
                                                    </div>

                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row", // Change to row to have icon and content side by side
                                                            alignItems: "center", // Center icon and content vertically
                                                            padding: "6px 20px", // Add padding to create some space around the content
                                                            marginBottom: "15px"
                                                        }}
                                                    >
                                                        <div style={{
                                                            width: "10%",
                                                            alignItems: "center",
                                                            alignContent: "center",
                                                            justifyContent: "center",
                                                            display: "flex"
                                                        }}>
                                                            <img src={timeCheckGrey} style={{width: "30px", height: "30px"}}/>
                                                        </div>
                                                        <div
                                                            style={{
                                                                width: "90%",
                                                                marginLeft: "10px",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignContent: "center",
                                                                justifyContent: "center"
                                                            }}
                                                        >
                                            <span
                                                style={{color: "black"}}>{new Date(item.start.seconds * 1000).toLocaleString('fr-FR', {
                                                timeZone: timeZoneState, // e.g., "Europe/Paris"
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit'
                                            })}</span>
                                                        </div>
                                                    </div>

                                                    {item?.prestaSnapshot?.selectedAddress?.length > 2 && (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "row", // Change to row to have icon and content side by side
                                                                alignItems: "center", // Center icon and content vertically
                                                                padding: "6px 20px", // Add padding to create some space around the content
                                                                marginBottom: "15px"
                                                            }}
                                                        >
                                                            <div style={{
                                                                width: "10%",
                                                                alignItems: "center",
                                                                alignContent: "center",
                                                                justifyContent: "center",
                                                                display: "flex"
                                                            }}>
                                                                <img src={pinMapGrey} style={{width: "30px", height: "30px"}}/>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    width: "90%",
                                                                    marginLeft: "10px",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    alignContent: "center",
                                                                    justifyContent: "center"
                                                                }}
                                                            >
                                                                <span style={{color: "black"}}>{item?.prestaSnapshot?.selectedAddress}</span>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {!item.isCanceled && (
                                                        item?.start?.toDate() > realDate ? (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row", // Change to row to have icon and content side by side
                                                                    alignItems: "center", // Center icon and content vertically
                                                                    padding: "6px 20px", // Add padding to create some space around the content
                                                                    marginBottom: "25px"
                                                                }}
                                                            >
                                                                <div style={{
                                                                    width: "10%",
                                                                    alignItems: "center",
                                                                    alignContent: "center",
                                                                    justifyContent: "center",
                                                                    display: "flex"
                                                                }}>

                                                                    <img src={timeTableOrangeIc} style={{width: "30px", height: "30px"}}/>

                                                                </div>
                                                                <div
                                                                    style={{
                                                                        width: "90%",
                                                                        marginLeft: "10px",
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        alignContent: "center",
                                                                        justifyContent: "center"
                                                                    }}
                                                                >
                                                                    <span style={{color: "#FBBC04", fontWeight:700}}>Réservation en cours</span>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "row", // Change to row to have icon and content side by side
                                                                    alignItems: "center", // Center icon and content vertically
                                                                    padding: "6px 20px", // Add padding to create some space around the content
                                                                    marginBottom: "25px"
                                                                }}
                                                            >
                                                                <div style={{
                                                                    width: "10%",
                                                                    alignItems: "center",
                                                                    alignContent: "center",
                                                                    justifyContent: "center",
                                                                    display: "flex"
                                                                }}>
                                                                    {!item.prestaSnapshot?.isDeposit ? (<img src={checkGreenIc} style={{width: "30px", height: "30px"}}/>):(
                                                                        <img src={dollarIc} style={{width: "30px", height: "30px"}}/>
                                                                    )}
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        width: "90%",
                                                                        marginLeft: "10px",
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        alignContent: "center",
                                                                        justifyContent: "center"
                                                                    }}
                                                                >
                                                                    <span style={{color: "#06AC2C", fontWeight:700}}>Réservation passée</span>
                                                                </div>
                                                            </div>
                                                        )

                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>

                            <div className="button-container" style={{marginTop:"70px"}}>
                                <Button onClick={() => handleFormSubmit()} className="button-style button-style-noborders" variant="contained">
                                    <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                        Enregistrer
                                    </Typography>
                                </Button>
                            </div>
                            <div className="button-container" style={{marginTop: "15px"}}>
                                <Button style={{marginBottom: "100px"}}
                                        className="button-style-nogr button-style-borders"
                                        disableElevation variant="contained" onClick={navigateHome}>
                                    <Typography variant="button" style={{textTransform: 'none'}}>
                                        Retour
                                    </Typography>
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className="sub-container-no-top">
                            <span className="title-style">Clients</span>
                            <div style={{
                                display: "flex", borderRadius: "15px", width: "100%",
                                boxShadow: "0px 4px 15px 0px rgba(218, 224, 229, 0.9)",
                                lineHeight: "20px", marginTop: "40px"
                            }}>
                            <span style={{
                                marginBottom: "20px",
                                marginLeft: "20px",
                                marginRight: "20px",
                                marginTop: "15px"
                            }}>
                                Cette fonctionnalité n’est pas disponible en forfait gratuit, nous vous invitons à passer au forfait Premium pour pouvoir l’utiliser.                            </span>
                            </div>
                            <div style={{
                                width: "100%",
                                justifyContent: "center",
                                display: "flex",
                                marginBottom: "40px"
                            }}>
                                <Chip onClick={() => navigate("/subscription")}
                                      icon={<img style={{width: "16px", height: "16px"}} src={icDiamond}></img>}
                                      style={{
                                          marginTop: "20px",
                                          background: "white",
                                          cursor: "pointer",
                                          border: "1px solid black",
                                          color: "black",
                                          fontWeight: "400",
                                          padding: "18px 18px",
                                          fontSize: "14px",
                                          borderRadius: "1000px"
                                      }}
                                      label="Faismoibg premium">
                                </Chip>
                            </div>

                            <div className="flex-direction height-anticipation-screen-2" style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                                alignContent: "center",
                                justifyContent: "center"
                            }}>
                                <img onLoad={handleImageLoad} width={300}
                                     className="fade-in"
                                     src="https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/NODELETE%2Fclients1.png?alt=media&token=10179ce0-fcc1-4f04-8c43-b45f392ca758"/>
                                <img onLoad={handleImageLoad} width={300}
                                     className="fade-in"
                                     src="https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/NODELETE%2Fclients2.png?alt=media&token=dfd4d406-ce2e-4f8e-a324-2917c7430ff6"/>
                            </div>

                            <div className="button-container" style={{marginTop: "70px"}}>
                                <Button style={{marginBottom: "100px"}}
                                        className="button-style-nogr button-style-borders"
                                        disableElevation variant="contained" onClick={navigateHome}>
                                    <Typography variant="button" style={{textTransform: 'none'}}>
                                        Retour
                                    </Typography>
                                </Button>
                            </div>
                        </div>
                    )
                )}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar}
                                          message={snackMessage} style={{width: "100%"}}/>
        </div>
    )
}