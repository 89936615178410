import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import Button from "@mui/material/Button";
import {
    Backdrop,
    Chip,
    CircularProgress, Dialog, DialogActions,
    DialogContent,
    InputAdornment,
    Skeleton,
    TextField,
    Typography, useMediaQuery, useTheme
} from "@mui/material";
import firebase from "firebase/compat/app";
import {HeaderHome} from "./headerHome";
import icDiamond from "../assets/diamondColored.svg";
import {getBlackListDoc, getCustomers, getUserBlocs, getUserSubscription, updateBlackList} from "../firebase";
import SnackbarWithCustomBackground from "./snackbar";
import {FaSearch} from "react-icons/fa";
import userCircleGreyIc from "../assets/userGreyCircleIc.svg";
import calendarCheckGreyIc from "../assets/calendarCheckGrey.svg";

export default function Blacklist() {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [isSub, setIsSub] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [queryResult, setQueryResult] = useState([]);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
    const [lastRemovedEmail, setLastRemovedEmail] = useState(false);
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(async (user) => {
            if (!user) {
                navigate("/");
            } else {
                const fetchData = async () => {
                    try {
                        const subObj = await getUserSubscription(user.uid);
                        if (subObj?.status==="trialing" || subObj?.status==="active"){
                            setIsSub(true);
                            const blackList = await getBlackListDoc(user.uid);
                            setQueryResult(blackList?.blacklist || []);
                            setShowSkeleton(false);
                        } else {
                            setIsSub(false);
                            setShowSkeleton(false);
                        }
                    } catch (e) {
                        console.error(e);
                        handleOpenSnackbar("Quelque chose s'est mal passé, actualisez la page ou contactez nous. " + e.message);
                        setShowSkeleton(false);
                    }
                }
                fetchData();
            }
        });
        // Cleanup the observer when the component is unmounted
        return () => {
            unregisterAuthObserver();
        }
    }, []);

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };
    const navigateHome = () => {
        navigate(-1);
    };

    const removeBlacklist = async (email) => {
        setShowBackdrop(true);
        try {
            await updateBlackList(btoa(email), false, firebase.auth().currentUser.uid);
            const blackList = await getBlackListDoc(firebase.auth().currentUser.uid);
            setQueryResult(blackList.blacklist);
            setLastRemovedEmail(email);
            setShowBackdrop(false);
            setIsConfirmDialogOpen(true);
        } catch (e) {
            console.error(e);
            handleOpenSnackbar("Quelque chose s'est mal passé, réessayez ou contactez nous. "+e.message);
        }
    };

    const filteredAndSortedResults = useMemo(() => {
        const lowerQuery = searchQuery.trim().toLowerCase();
        return queryResult
            .filter((email) => {
                return (
                    lowerQuery === '' || email.toLowerCase().includes(lowerQuery)
                );
            })
            .sort((a, b) => a.localeCompare(b));
    }, [queryResult, searchQuery]);

    const handleConfirmDialogClose = () => {
        setIsConfirmDialogOpen(false);
        setLastRemovedEmail(null);
    };

    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            {showSkeleton ?
                (
                    <div className="sub-container-no-top">
                        <div style={{
                            display: "flex", width: "100%", flexDirection: "column",
                            justifyContent: "center", alignContent: "center", alignItems: "center"
                        }}>
                            <Skeleton style={{borderRadius: "15px"}} variant="text" sx={{fontSize: '2rem'}}
                                      width="70%"/>
                            <Skeleton style={{marginTop: "50px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                            <Skeleton style={{marginTop: "25px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                            <Skeleton style={{marginTop: "25px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                        </div>
                    </div>
                ) : (

                    isSub ? (
                        <div className="sub-container-no-top">
                            <span className="title-style">Blacklist</span>
                            <div className="field-container" style={{marginTop:"30px", display:"flex", flexDirection:"row", alignContent:"top", alignItems:"top"}} >
                                <div className="title-field-container" style={{width:"70%"}}>
                                          <span className="text-field-style">
                                              Rechercher un client
                                          </span>
                                </div>
                            </div>
                            <div style={{display:"flex", flexDirection:"row", width:"100%"}}>
                                <TextField style={{width:"100%"}} className="field-style" id="outlined-basic" label="Rechercher" variant="outlined" value={searchQuery}
                                           onChange={(e) => setSearchQuery(e.target.value)}
                                           InputProps={{
                                               endAdornment: (
                                                   <InputAdornment position="end">
                                                       <FaSearch style={{color:"black"}} />
                                                   </InputAdornment>
                                               ),
                                           }}/>

                            </div>
                            <div style={{display:"flex", flexDirection:"column", borderRadius:"15px", alignContent:"center", justifyContent:"center",
                                alignItems:"center", background: "linear-gradient(90deg, rgba(255, 8, 68, 0.2) 0%, rgba(72, 8, 255, 0.2) 100%)",
                                height:"44px", width:"80%", marginTop:"40px"}}>
                                    <span>
                                        Résultats: <b>{queryResult.filter((email)=> {
                                        const lowerQuery = searchQuery.trim().toLowerCase();
                                        return lowerQuery === '' || email.toLowerCase().includes(lowerQuery);
                                    }).length}</b>  sur {queryResult.length}
                                    </span>
                            </div>
                            <div className="field-container" style={{marginTop:"30px", display:"flex", flexDirection:"row", alignContent:"top", alignItems:"top"}} >
                                <div className="title-field-container" style={{width:"70%"}}>
                                          <span className="text-field-style">
                                              Liste des clients blacklistés
                                          </span>
                                </div>
                            </div>
                            <div style={{ maxHeight: '400px', overflowY: 'scroll', padding:"12px", width:"95%"}}>
                                {queryResult.filter((email)=> {
                                    const lowerQuery = searchQuery.trim().toLowerCase();
                                    return lowerQuery === '' ||
                                        email.toLowerCase().includes(lowerQuery)
                                }).sort((a, b) => a.localeCompare(b)
                                ).map((email, index)=> (
                                        <div key={index+1} style={{display:"flex", flexDirection:"column", width:"100%", borderRadius:"15px", marginBottom:"12px",
                                            boxShadow:"0px 4px 15px 0px rgba(218, 224, 229, 0.9)"}}>
                                            <div style={{display:"flex", flexDirection:"row", justifyContent:"start", alignContent:"center", alignItems:"center",margin:"20px 30px"}}>
                                                <img src={userCircleGreyIc}/>
                                                <span style={{marginLeft:"12px", overflowWrap:"break-word", width:"85%"}}>{email}</span>
                                            </div>
                                            <div style={{marginBottom:"20px", marginLeft:"30px", marginRight:"20px", display:"flex", flexDirection:"row"}}>
                                                <Button onClick={()=>removeBlacklist(email)} disableElevation component="label" className="button-style-noborders-lil" variant="contained" style={{background:"linear-gradient(90deg, #FF0844 0%, #4808FF 92.63%)",
                                                    color:"white"}}>
                                                    <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px", lineHeight:"16px" }}>
                                                        Ré-autoriser
                                                    </Typography>
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>

                            {
                                filteredAndSortedResults.length===0 && (
                                    <span style={{marginTop:"20px"}}>Aucun résultat</span>
                                )
                            }

                            <div className="button-container" style={{marginTop: "70px"}}>
                                <Button style={{marginBottom: "100px"}}
                                        className="button-style-nogr button-style-borders"
                                        disableElevation variant="contained" onClick={navigateHome}>
                                    <Typography variant="button" style={{textTransform: 'none'}}>
                                        Retour
                                    </Typography>
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className="sub-container-no-top">
                            <span className="title-style">Blacklist</span>
                            <div style={{
                                display: "flex", borderRadius: "15px", width: "100%",
                                boxShadow: "0px 4px 15px 0px rgba(218, 224, 229, 0.9)",
                                lineHeight: "20px", marginTop: "40px"
                            }}>
                            <span style={{
                                marginBottom: "20px",
                                marginLeft: "20px",
                                marginRight: "20px",
                                marginTop: "15px"
                            }}>
                                Cette fonctionnalité n’est pas disponible en forfait gratuit, nous vous invitons à passer au forfait Premium pour pouvoir l’utiliser.                            </span>
                            </div>
                            <div style={{
                                width: "100%",
                                justifyContent: "center",
                                display: "flex",
                                marginBottom: "40px"
                            }}>
                                <Chip onClick={() => navigate("/subscription")}
                                      icon={<img style={{width: "16px", height: "16px"}} src={icDiamond}></img>}
                                      style={{
                                          marginTop: "20px",
                                          background: "white",
                                          cursor: "pointer",
                                          border: "1px solid black",
                                          color: "black",
                                          fontWeight: "400",
                                          padding: "18px 18px",
                                          fontSize: "14px",
                                          borderRadius: "1000px"
                                      }}
                                      label="Faismoibg premium">
                                </Chip>
                            </div>

                            <div style={{height:"375px"}}>
                                <img onLoad={handleImageLoad} width={300}
                                     className="fade-in" src="https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/NODELETE%2Fblacklist.png?alt=media&token=73308d68-de0d-46bb-8bc5-645e36532393"/>
                            </div>

                            <div className="button-container" style={{marginTop: "70px"}}>
                                <Button style={{marginBottom: "100px"}}
                                        className="button-style-nogr button-style-borders"
                                        disableElevation variant="contained" onClick={navigateHome}>
                                    <Typography variant="button" style={{textTransform: 'none'}}>
                                        Retour
                                    </Typography>
                                </Button>
                            </div>
                        </div>
                    )
                )}
            <Dialog
                open={isConfirmDialogOpen}
                onClose={handleConfirmDialogClose}
                fullWidth={!isLargeScreen}
                PaperProps={{
                    style: {
                        minWidth: isLargeScreen ? '500px' : 'auto',
                        maxWidth: isLargeScreen ? '500px' : 'auto',
                    },
                }}
            >
                <DialogContent style={{ backgroundColor: "white" }}>
                    <div style={{display:"flex", flexDirection:"column"}}>
                        <Typography variant="body1" style={{fontWeight:400, fontSize:"14px", marginTop:"12px", textAlign:"center"}}>
                          <b>{lastRemovedEmail}</b> est à nouveau autorisé(e) à prendre rendez-vous ✅.
                        </Typography>
                    </div>
                </DialogContent>
                <DialogActions style={{ backgroundColor: "white" }}>
                    <div style={{ width: "100%", display: "flex", padding:"10px 20px", marginBottom:"20px"}}>
                        <Button onClick={()=>setIsConfirmDialogOpen(false)} className="button-style button-style-noborders" variant="contained">
                            <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                OK, c'est compris
                            </Typography>
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar}
                                          message={snackMessage} style={{width: "100%"}}/>
        </div>
    )
}