import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {
    Backdrop,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent, DialogTitle, MenuItem,
    Skeleton,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {Gallery} from "./carouselpresta";
import {CalendarOptions, ICalendar} from "datebook";
import { saveAs } from 'file-saver';
import pinMapBlue from "../assets/ph_map-pin-blue.svg";
import calPlusBlue from "../assets/ph_calendar-check-blue.svg";
import userCircleBlue from "../assets/ph_user-circle-fill-blue.svg";
import {cancelRdvById, getRdvById, getUser, validateRdvById} from "../firebase";
import firebase from "firebase/compat/app";
import 'firebase/firestore'
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import SnackbarWithCustomBackground from "./snackbar";
import {HeaderHome} from "./headerHome";
import {useParams} from "react-router";
import DisplayCarousel from "./displaycarousel";
import cancelRound from "../assets/cancelRound.svg";
import calPlusDark from "../assets/ph_calendar-x-dark.svg";
import pinMapDark from "../assets/ph_map-pin-dark.svg";
import userCircleDark from "../assets/ph_user-circle-fill-dark.svg";
import checkGreenIc from "../assets/ic_check.svg";

const cancelOptions = ["Double réservation", "Imprévu Santé", "Non versement d’acompte", "Nouvelle réservation proposée",
    "Problèmes de locaux", "Retard conséquent du client", "Urgence personnelle", "Vacances ou absence", "Autre"];

function durationFormatMin(duration){
    const [hours, minutes] = duration.split('h');
    return parseInt(hours) === 0 ? `${minutes} min` : `${duration}`;
}
function convertToTimeZone(date, timezone) {

    const options = {
        timeZone: timezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    };

    const formatter = new Intl.DateTimeFormat('en-US', options);
    const formattedDate = formatter.format(date);
    return new Date(formattedDate);
}

function formatHour(date) {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}h${minutes}`;
}

function formatDateToFrench(date) {
     const options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
     };
     let frenchDate = new Intl.DateTimeFormat('fr-FR', options).format(date);
     frenchDate = frenchDate.replace(":", "h");
     return frenchDate;
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function formatDate(date) {
    const dayNames = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
    const monthNames = Object.keys(monthMap);
    return `${dayNames[date.getDay()]} ${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
}

const monthMap = {
    "janvier": 0,
    "février": 1,
    "mars": 2,
    "avril": 3,
    "mai": 4,
    "juin": 5,
    "juillet": 6,
    "août": 7,
    "septembre": 8,
    "octobre": 9,
    "novembre": 10,
    "décembre": 11
};

export default function RdvDetails() {
    const { id } = useParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [timeZoneState, setTimeZoneState] =  useState("Europe/Paris");
    const [rdvObj, setRdvObj] =  useState(null);
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [showBackDrop, setShowBackDrop] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [isAlreadyRefunded, setIsAlreadyRefunded] = useState(false);
    const [selectedCancel, setSelectedCancel] = useState("");
    const [userData, setUserData] = useState(null);
    const [displayQ, setDisplayQ] = useState(false);
    const [isDepositDialogOpen, setIsDepositDialogOpen] = useState(false);
    const cancelEvent = firebase.functions().httpsCallable('cancelEvent');

    useEffect( ()=>{


        const unregisterAuthObserver = firebase.auth().onAuthStateChanged( async(user) => {
            let timezone = "Europe/Paris";
            if (!user) {
                navigate("/");
            } else {
                const fetchData = async () => {

                    const userData = await getUser(user.uid);
                    const rdvData = await getRdvById(id);
                    setRdvObj(rdvData);
                    setUserData(userData);
                    if (!rdvData){
                        await navigate("/");
                    }
                    if (userData?.timezone){
                        timezone = userData.timezone;
                        setTimeZoneState(timezone);
                    }

                }
                try {
                    await fetchData();
                } catch (e) {
                    console.log(e.message);
                    //await navigate("/");
                    handleOpenSnackbar("Quelque chose s'est mal passé... Vérifiez votre lien, ainsi que la connection soit effectuée sur le bon compte. Error => " + e.message);
                    return;
                }
                setShowSkeleton(false);
            }
        });
        // Cleanup the observer when the component is unmounted
        return () => {
            unregisterAuthObserver();
        }
    }, []);


    const navigateHome = async () => {
        navigate("/home");
    }

    const navigateRdv = async () => {
        navigate(-1);
    }

    /*if (rdvObj) {
        return (
            <div>
                <div style={{marginTop:"30px"}}>
                    Cette URL ne semble pas valide
                </div>
                <div className="button-container">
                    <Button className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateHome}>
                        <Typography variant="button" style={{ textTransform: 'none' }}>
                            Retour
                        </Typography>
                    </Button>
                </div>
            </div>

        )
    }*/

    const handleAddToCalendar = async () => {
        const config: CalendarOptions = {
            title: rdvObj.prestaSnapshot.title,
            description: "Page de réservation : https://my.faismoibg.com/rdvdetails/" + id +  " Description : " + rdvObj.prestaSnapshot.description,
            location: "",
            start: new Date(rdvObj.start.seconds * 1000),
            end: new Date(rdvObj.end.seconds * 1000),
        }
        const icalendar = new ICalendar(config);
        const blob = new Blob([icalendar.render()], {
            type: 'text/calendar'
        })
        saveAs(blob, 'rdv' + new Date().toISOString() + '.ics')
    }

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const openDialog = () => {
        if (!rdvObj.prestaSnapshot?.isDeposit){
            setIsDialogOpen(true);
        } else if (rdvObj?.depositRef?.length>0){
            setIsDepositDialogOpen(true);
        } else {
            setIsDialogOpen(true);
        }
    };

    const navigateToCancel = async () => {
        setIsDialogOpen(false);
        setShowBackDrop(true);
        if (selectedCancel.length<2){
            handleOpenSnackbar("Vous devez séléctionner un motif.")
            return;
        }

        const updateIsCanceled = {
            isCanceled : true,
            cancelReason : selectedCancel,
            cancelPerson : userData.pseudo,
            isAlreadyRefunded : isAlreadyRefunded,
            timestamp : new Date()
        }

        await cancelRdvById(updateIsCanceled, id);

        if (rdvObj.isGoogle){
            const today = new Date(rdvObj.start.seconds * 1000);
            const tomorrow = new Date(rdvObj.end.seconds * 1000);
            today.setDate(today.getDate()-1);
            tomorrow.setDate(tomorrow.getDate() + 1);
            const canceledGoogleEvent = await cancelEvent({
                uidFromCall : rdvObj.uid,
                eventId : Array.from(new TextEncoder().encode(String(rdvObj.documentId)))
                    .map(byte => (byte < 10 ? '0' : '') + byte.toString(32))
                    .join('')
                    .toLowerCase(),
                today : today.toISOString(),
                tomorrow : tomorrow.toISOString()
            })
            /*
            if (canceledGoogleEvent.data){
                setHasGoogleBeenRemoved(true);
            }
            */
        }
        setShowBackDrop(false);
        window.location.reload();

        /*To be replaced soon
        await navigate("/appointcancel/"+rdvObj.documentId, {
            state : {
                cancelReason : selectedCancel,
                cancelPerson : userData.pseudo
            }
        });*/
    }

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const handleCancelSelection = (option) => {
        setSelectedCancel(option);
    }

    const navigateFromToAnswers = () => {
        setDisplayQ(!displayQ);
        window.scrollTo(0, 0);
    }

    const handleDepositDialogCloseOutside = () => {
        setIsDepositDialogOpen(false);
    };

    const handleCancelDepClicked = (isAlreadyRefunded) => {
        setIsAlreadyRefunded(isAlreadyRefunded);
        setIsDepositDialogOpen(false);
        setIsDialogOpen(true);
    };

    const handleRdvValidated = async () => {
        setShowBackDrop(true);
        if (new Date(rdvObj.start.seconds * 1000) < new Date()){
            handleOpenSnackbar("Ce rdv est déjà passé");
            return;
        }

        const dbObj = {
            timestamp : new Date(),
            isValidated : true
        }

        try {
            await validateRdvById(dbObj, rdvObj.documentId);
        } catch (e) {
            handleOpenSnackbar("Quelque chose s'est mal passé, contactez nous.");
            setShowBackDrop(false);
            return;
        }
        setShowBackDrop(false);
        window.location.reload();
    };

    const handleRdvDiscarded = () => {
        setIsDialogOpen(true);
    };

    const navigateToAppointment = () => {
        navigate("/appointdate/"+ rdvObj?.prestaSnapshot?.documentId + "?rid="+rdvObj?.documentId);
    };

    // Continue dialog

    const [openWarnDialog, setOpenWarnDialog] = useState(false);

    const handleWarnDialogCloseOutside = (event) => {
        setOpenWarnDialog(false);
    };

    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            {showSkeleton ?
                (
                    <div className="sub-container-no-top">
                        <div style={{
                            display: "flex", width: "100%", flexDirection: "column",
                            justifyContent: "center", alignContent: "center", alignItems: "center"
                        }}>
                            <Skeleton style={{borderRadius: "15px"}} variant="text" sx={{fontSize: '2rem'}}
                                      width="70%"/>
                            <Skeleton style={{marginTop: "50px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                            <Skeleton style={{marginTop: "25px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                            <Skeleton style={{marginTop: "25px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                        </div>
                    </div>
                ) : (
                    !showSkeleton && displayQ ? (
                        <div className="sub-container-no-top">
                            <span className="title-style" style={{textAlign:"center"}}>Informations complémentaires</span>
                            <span style={{textAlign:"center", width:"100%", marginTop:"28px", fontSize:"20px", whiteSpace:"pre-line"}}>
                            {rdvObj?.prestaSnapshot?.intro}
                            </span>
                            {rdvObj?.answers?.length>0 && rdvObj?.answers.map((object,index)=> (
                                <div style={{display:"flex", flexDirection:"column", width:"100%", marginTop:"50px"}} key={index}>
                                <span style={{color:"#4808FF", fontSize:"16px", lineHeight:"24px", fontWeight:400}}>
                                    Question {index + 1}
                                </span>
                                    <span style={{color:"#17181A", fontSize:"16px", lineHeight:"24px", fontWeight:700, marginTop:"20px"}}>
                                    {object?.question}
                                </span>
                                    <span style={{color:"#17181A", fontSize:"16px", lineHeight:"24px", fontWeight:400, marginTop:"20px"}}>
                                    {object?.answer}
                                </span>
                                    {
                                        object?.checked && object?.images?.length > 0 && (
                                            <div style={{marginTop:"15px", display:"flex", width:"100%"}}>
                                                {object?.images && object?.images?.length<1 ?
                                                    <div>Aucune image</div> : <DisplayCarousel style={{marginTop:"10px"}} imagesArray={object?.images || []}
                                                    />
                                                }
                                            </div>
                                        )
                                    }
                                </div>

                            ))}

                            <div className="button-container" style={{marginTop:"70px", marginBottom:"100px"}}>
                                <Button style={{marginBottom:"100px"}} className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateFromToAnswers}>
                                    <Typography variant="button" style={{ textTransform: 'none' }}>
                                        Retour
                                    </Typography>
                                </Button>
                            </div>

                        </div>
                        ) : (


                                rdvObj?.isCanceled ? (
                                    <div className="sub-container-no-top">
                                        <span className="title-style" style={{textAlign:"center"}}>Cette réservation est <span className="title-style" style={{textAlign:"center", color:"#FF0844"}}>annulée</span></span>
                                        <span style={{textAlign:"center", width:"100%", marginTop:"28px", fontSize:"20px", fontWeight:400, fontFamily:"Nunito"}}>
                                                        Depuis le {formatDateToFrench(convertToTimeZone(new Date(rdvObj?.timestamp?.seconds * 1000), timeZoneState))}
                                        </span>
                                        <span style={{textAlign:"center", width:"100%", marginTop:"28px", fontSize:"20px", fontWeight:400, fontFamily:"Nunito"}}>
                                                        Nous avons envoyé un email de confirmation à votre client.
                                        </span>
                                        {rdvObj?.prestaSnapshot?.isDeposit && rdvObj?.depositRef?.length>1 && (
                                            <div style={{width:"100%", marginBottom:"18px"}}>
                                                <div style={{display: "flex", padding: "20px 20px 20px 20px", flexDirection: "column", justifyContent: "center", alignItems: "flex-start",
                                                    gap: "30px", borderRadius: "15px",
                                                    background: "linear-gradient(180deg, #DAE0E5 0%, #FFF 100%)",
                                                    boxShadow: "0px 4px 15px 0px rgba(218, 224, 229, 0.90)", marginTop:"60px"}}>

                                                    <div style={{flexDirection:"column", display:"flex", width:"100%"}}>

                                                        <div style={{flexDirection:"row", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                                                <span style={{fontWeight: 700, lineHeight: "20px"}}>
                                                    Acompte confirmé
                                                </span>
                                                            <img style={{marginLeft:"20px",marginRight:"7px"}} src={checkGreenIc}/>
                                                        </div>

                                                        <div style={{flexDirection:"row", display:"flex", alignItems:"center", justifyContent:"space-between", marginTop:"10px"}}>
                                                <span style={{ lineHeight: "20px"}}>
                                                    Montant
                                                </span>
                                                            <div style={{display: "flex", padding:" 5px 10px", alignItems: "center", gap: "10px", borderRadius:"99px", background:"#DAE0E5", fontWeight:700, fontSize:"14px"}}>
                                                                {rdvObj?.prestaSnapshot?.deposit?.amount}
                                                            </div>
                                                        </div>
                                                        <div style={{flexDirection:"row", display:"flex", alignItems:"center", justifyContent:"start", marginTop:"10px"}}>
                                                <span style={{ lineHeight: "20px"}}>
                                                    Ref :
                                                </span>
                                                            <div style={{marginLeft:"6px"}}>
                                                                {rdvObj?.depositRef}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div
                                            style={{
                                                display: "flex",
                                                marginTop: "32px",
                                                minHeight: "10px",
                                                borderRadius: "15px",
                                                boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.25)",
                                                width: "100%",
                                                flexDirection: "column", // Change to column to stack items vertically
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row", // Change to row to have icon and content side by side
                                                    alignItems: "center", // Center icon and content vertically
                                                    padding: "20px 20px",
                                                }}
                                            >
                                                <div style={{display:"flex", flexDirection:"column", width:"70%"}}>
                                                <span style={{fontWeight:700}}>
                                                    Motif d’annulation
                                                </span>
                                                    <span style={{}}>
                                                        {rdvObj?.cancelReason}
                                                    </span>
                                                    <br/>
                                                    <span style={{fontWeight:700}}>
                                                        Effectuée par
                                                     </span>
                                                    <span style={{}}>
                                                        {rdvObj?.cancelPerson}
                                                    </span>

                                                </div>
                                                <div style={{display:"flex", flexDirection:"column", width:"30%",alignContent:"center", alignItems:"center", justifyContent:"center"}}>
                                                    <img style={{alignSelf:"center"}} src={cancelRound}/>
                                                </div>
                                            </div>

                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                background: "linear-gradient(180deg, #8A9699 0%, #000 100%)",
                                                marginTop: "50px",
                                                minHeight: "10px",
                                                borderRadius: "15px",
                                                boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.25)",
                                                width: "100%",
                                                flexDirection: "column", // Change to column to stack items vertically
                                            }}
                                        >
                                            <div style={{ display: "flex", justifyContent: "flex-end", marginTop:"25px", marginRight:"5px" }}>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row", // Change to row to have icon and content side by side
                                                    alignItems: "center", // Center icon and content vertically
                                                    padding: "1px 20px", // Add padding to create some space around the content
                                                    marginBottom:"25px"
                                                }}
                                            >
                                                <div style={{ width: "10%",alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex" }}>
                                                    <img src={calPlusDark} style={{width:"30px", height:"30px"}}/>
                                                </div>
                                                <div
                                                    style={{
                                                        width: "90%", // Adjusted width to make space for the close icon
                                                        marginLeft: "10px",
                                                    }}
                                                >
                                                    <span style={{color:"white"}}>{formatDate(convertToTimeZone(new Date(rdvObj.start.seconds * 1000)))} à {formatHour(convertToTimeZone(new Date(rdvObj.start.seconds * 1000)))}</span>
                                                </div>
                                            </div>
                                            {(rdvObj?.prestaSnapshot?.isAddressVisible && rdvObj?.prestaSnapshot?.selectedAddress?.length>1) ?
                                                (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row", // Change to row to have icon and content side by side
                                                            alignItems: "center", // Center icon and content vertically
                                                            padding: "1px 20px", // Add padding to create some space around the content
                                                            marginBottom:"25px"
                                                        }}
                                                    >
                                                        <div style={{ width: "10%",alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex"  }}>
                                                            <img src={pinMapDark} style={{width:"30px", height:"30px"}}/>
                                                        </div>
                                                        <div
                                                            style={{
                                                                width: "90%", // Adjusted width to make space for the close icon
                                                                marginLeft: "10px",
                                                            }}
                                                        >
                                                            <span style={{color:"white"}}>{rdvObj.prestaSnapshot.selectedAddress}</span>
                                                        </div>
                                                    </div>
                                                ) :
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row", // Change to row to have icon and content side by side
                                                        alignItems: "center", // Center icon and content vertically
                                                        padding: "1px 20px", // Add padding to create some space around the content
                                                        marginBottom:"25px"
                                                    }}
                                                >
                                                    <div style={{ width: "10%",alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex"  }}>
                                                        <img src={pinMapDark} style={{width:"30px", height:"30px"}}/>
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: "90%", // Adjusted width to make space for the close icon
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                        <span style={{color:"white"}}>Vous n'avez pas séléctionné d'adresse pour cette prestation</span>
                                                    </div>
                                                </div>

                                            }

                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row", // Change to row to have icon and content side by side
                                                    alignItems: "center", // Center icon and content vertically
                                                    padding: "1px 20px", // Add padding to create some space around the content
                                                    marginBottom:"25px"
                                                }}
                                            >
                                                <div style={{ width: "10%",alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex"  }}>
                                                    <img src={userCircleDark} style={{width:"30px", height:"30px"}}/>
                                                </div>
                                                <div
                                                    style={{
                                                        width: "90%", // Adjusted width to make space for the close icon
                                                        marginLeft: "10px",
                                                        display:"flex",
                                                        flexDirection:"column"
                                                    }}
                                                >
                                                    <span style={{color:"white", fontWeight:"700"}}>{rdvObj?.custName}</span>
                                                    <span style={{color:"white"}}>{rdvObj?.custAddress}</span>
                                                    <span style={{color:"white"}}>{rdvObj?.custPhone}</span>
                                                </div>
                                            </div>



                                        </div>

                                        <div style={{display:"flex", marginTop:"18px", width:"100%"}}>
                                            <span className="title-style-presta">{rdvObj?.prestaSnapshot?.title}</span>
                                        </div>

                                        <div style={{display:"flex", flexDirection:"row", marginTop:"10px", width:"100%"}}>
                                            <div style={{display: "flex", padding: "5px 10px", alignItems: "center", gap: "10px", borderRadius:"99px", background:"#DAE0E5"}}>
                                                <span style={{color: "#000", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "normal"}}>
                                                    {rdvObj?.prestaSnapshot?.price}
                                                </span>
                                            </div>
                                            <div style={{display: "flex", padding: "5px 10px", alignItems: "center", gap: "10px", borderRadius:"99px", background:"#DAE0E5", marginLeft:"10px"}}>
                                        <span style={{color: "#000", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "normal"}}>
                                            {durationFormatMin(rdvObj?.prestaSnapshot?.prestaDuration)}
                                        </span>
                                            </div>
                                        </div>

                                        <div style={{display:"flex", width:"100%", marginTop:"20px"}}>
                                            {rdvObj?.prestaSnapshot?.imageLinks?.length>0 ? <Gallery urls={rdvObj?.prestaSnapshot?.imageLinks}>
                                            </Gallery> : <Gallery urls={[{
                                                imageLink:"https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA..",
                                                vlink:""
                                            }]}>
                                            </Gallery>}

                                        </div>

                                        <span style={{fontSize:"16px", marginTop:"20px", display:"flex", width:"100%", whiteSpace:"pre-line"}}>
                                            {rdvObj?.prestaSnapshot?.description}
                                        </span>
                                        <div className="button-container" style={{marginTop:"70px"}}>
                                            <Button style={{marginBottom:"70px"}} className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateRdv}>
                                                <Typography variant="button" style={{ textTransform: 'none' }}>
                                                    Retour
                                                </Typography>
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="sub-container-no-top">
                                        {rdvObj?.isRescheduled ?
                                            (
                                                <div className="title-style" style={{textAlign:"center"}}>La date de votre réservation a bien été
                                                        <div className="sub-container-no-top">
                                                            <span className="title-style" style={{textAlign:"center", color:"#28A745"}}>mise à jour</span>
                                                            <span style={{textAlign:"center", width:"100%", marginTop:"28px", fontSize:"20px", fontWeight:400, fontFamily:"Nunito"}}>
                                                            Depuis le {formatDateToFrench(convertToTimeZone(new Date(rdvObj?.timestamp?.seconds * 1000), timeZoneState))}
                                                        </span>
                                                            <span style={{textAlign:"center", width:"100%", marginTop:"28px", fontSize:"20px", fontWeight:400, fontFamily:"Nunito"}}>
                                                            Nous avons envoyé un email de confirmation à votre client.
                                                        </span>
                                                        </div>
                                                </div>
                                            )
                                            :(
                                                <div className="title-style" style={{textAlign:"center"}}>La réservation est {rdvObj?.prestaSnapshot?.isValidation && !rdvObj?.isValidated ? (
                                                        <span className="title-style" style={{textAlign:"center", color:"#FBBC04"}}>en attente de confirmation</span>
                                                    ):
                                                    (
                                                        <div className="sub-container-no-top">
                                                            <span className="title-style" style={{textAlign:"center", color:"#28A745"}}>validée</span>
                                                            <span style={{textAlign:"center", width:"100%", marginTop:"28px", fontSize:"20px", fontWeight:400, fontFamily:"Nunito"}}>
                                                            Depuis le {formatDateToFrench(convertToTimeZone(new Date(rdvObj?.timestamp?.seconds * 1000), timeZoneState))}
                                                        </span>
                                                            <span style={{textAlign:"center", width:"100%", marginTop:"28px", fontSize:"20px", fontWeight:400, fontFamily:"Nunito"}}>
                                                            Nous avons envoyé un email de confirmation à votre client.
                                                        </span>
                                                        </div>
                                                    )}
                                                </div>
                                             )
                                        }

                                        {rdvObj?.prestaSnapshot?.isValidation && !rdvObj?.isValidated && (
                                            <div className="sub-container-no-top">
                                                <span style={{textAlign:"center", width:"100%", marginTop:"28px", fontSize:"20px"}}>
                                                   Depuis le {formatDateToFrench(convertToTimeZone(new Date(rdvObj?.timestamp?.seconds * 1000), timeZoneState))}
                                                </span>
                                                <span style={{textAlign:"center", width:"100%", marginTop:"28px", fontSize:"20px"}}>
                                                   Vous avez reçu une demande de réservation.
                                                </span>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        marginTop: "32px",
                                                        minHeight: "10px",
                                                        borderRadius: "15px",
                                                        boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.25)",
                                                        width: "100%",
                                                        flexDirection: "column", // Change to column to stack items vertically
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row", // Change to row to have icon and content side by side
                                                            alignItems: "center", // Center icon and content vertically
                                                            padding: "20px 20px",
                                                        }}
                                                    >
                                                        <div style={{display:"flex", flexDirection:"column", width:"90%"}}>
                                                        <span style={{fontWeight:700,width:"80%"}}>
                                                            Demande de réservation envoyée par votre client
                                                        </span>
                                                        </div>
                                                        <div style={{display:"flex", flexDirection:"column", width:"20%",alignContent:"center", alignItems:"center", justifyContent:"center"}}>
                                                            <img style={{alignSelf:"end"}} src={cancelRound}/>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row", // Change to row to have icon and content side by side
                                                            alignItems: "center", // Center icon and content vertically
                                                            paddingTop: "10px",
                                                            paddingLeft:"20px",
                                                            paddingRight:"20px",
                                                            paddingBottom:"20px"
                                                        }}
                                                    >
                                                        <Button onClick={handleRdvValidated} disableElevation component="label" className="button-style-noborders-lil" variant="contained" style={{background:"linear-gradient(90deg, #FF0844 0%, #4808FF 92.63%)",
                                                            color:"white"}}>
                                                            <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px", lineHeight:"16px" }}>
                                                                Valider
                                                            </Typography>
                                                        </Button>
                                                        <Button onClick={handleRdvDiscarded} disableElevation component="label" className="button-style-noborders-lil" variant="contained" style={{background:"white",
                                                            color:"black", border:"1px solid black", marginLeft:"20px"}}>
                                                            <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px", lineHeight:"16px" }}>
                                                                Refuser
                                                            </Typography>
                                                        </Button>

                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {rdvObj?.prestaSnapshot?.isDeposit && rdvObj?.depositRef?.length>1 && (
                                            <div style={{width:"100%"}}>
                                                <div style={{display: "flex", padding: "20px 20px 20px 20px", flexDirection: "column", justifyContent: "center", alignItems: "flex-start",
                                                    gap: "30px", borderRadius: "15px",
                                                    background: "linear-gradient(180deg, #DAE0E5 0%, #FFF 100%)",
                                                    boxShadow: "0px 4px 15px 0px rgba(218, 224, 229, 0.90)", marginTop:"60px"}}>

                                                    <div style={{flexDirection:"column", display:"flex", width:"100%"}}>

                                                        <div style={{flexDirection:"row", display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                                                <span style={{fontWeight: 700, lineHeight: "20px"}}>
                                                    Acompte confirmé
                                                </span>
                                                            <img style={{marginLeft:"20px",marginRight:"7px"}} src={checkGreenIc}/>
                                                        </div>

                                                        <div style={{flexDirection:"row", display:"flex", alignItems:"center", justifyContent:"space-between", marginTop:"10px"}}>
                                                <span style={{ lineHeight: "20px"}}>
                                                    Montant
                                                </span>
                                                            <div style={{display: "flex", padding:" 5px 10px", alignItems: "center", gap: "10px", borderRadius:"99px", background:"#DAE0E5", fontWeight:700, fontSize:"14px"}}>
                                                                {rdvObj?.prestaSnapshot?.deposit?.amount}
                                                            </div>
                                                        </div>
                                                        <div style={{flexDirection:"row", display:"flex", alignItems:"center", justifyContent:"start", marginTop:"10px"}}>
                                                <span style={{ lineHeight: "20px"}}>
                                                    Ref :
                                                </span>
                                                            <div style={{marginLeft:"6px"}}>
                                                                {rdvObj?.depositRef}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div
                                            style={{
                                                display: "flex",
                                                background: "linear-gradient(180deg, #4808FF 0%, #9747FF 100%)",
                                                marginTop: "50px",
                                                minHeight: "10px",
                                                borderRadius: "15px",
                                                boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.25)",
                                                width: "100%",
                                                flexDirection: "column", // Change to column to stack items vertically
                                            }}
                                        >
                                            <div style={{ display: "flex", justifyContent: "flex-end", marginTop:"25px", marginRight:"5px" }}>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row", // Change to row to have icon and content side by side
                                                    alignItems: "center", // Center icon and content vertically
                                                    padding: "1px 20px", // Add padding to create some space around the content
                                                    marginBottom:"25px"
                                                }}
                                            >
                                                <div style={{ width: "10%",alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex" }}>
                                                    <img src={calPlusBlue} style={{width:"30px", height:"30px"}}/>
                                                </div>
                                                <div
                                                    style={{
                                                        width: "90%", // Adjusted width to make space for the close icon
                                                        marginLeft: "10px",
                                                    }}
                                                >
                                                    <span style={{color:"white"}}>{capitalizeFirstLetter(formatDateToFrench(convertToTimeZone(new Date(rdvObj?.start?.seconds * 1000), timeZoneState)))}</span>
                                                </div>
                                            </div>
                                            {rdvObj?.prestaSnapshot?.selectedAddress?.length>1 ?
                                                (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row", // Change to row to have icon and content side by side
                                                            alignItems: "center", // Center icon and content vertically
                                                            padding: "1px 20px", // Add padding to create some space around the content
                                                            marginBottom:"25px"
                                                        }}
                                                    >
                                                        <div style={{ width: "10%",alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex"  }}>
                                                            <img src={pinMapBlue} style={{width:"30px", height:"30px"}}/>
                                                        </div>
                                                        <div
                                                            style={{
                                                                width: "90%", // Adjusted width to make space for the close icon
                                                                marginLeft: "10px",
                                                            }}
                                                        >
                                                            <span style={{color:"white"}}>{rdvObj?.prestaSnapshot?.selectedAddress}</span>
                                                        </div>
                                                    </div>
                                                ) :
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row", // Change to row to have icon and content side by side
                                                        alignItems: "center", // Center icon and content vertically
                                                        padding: "1px 20px", // Add padding to create some space around the content
                                                        marginBottom:"25px"
                                                    }}
                                                >
                                                    <div style={{ width: "10%",alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex"  }}>
                                                        <img src={pinMapBlue} style={{width:"30px", height:"30px"}}/>
                                                    </div>
                                                    <div
                                                        style={{
                                                            width: "90%", // Adjusted width to make space for the close icon
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                        <span style={{color:"white"}}>Vous n'avez pas séléctionné d'adresse pour cette prestation</span>
                                                    </div>
                                                </div>

                                            }

                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row", // Change to row to have icon and content side by side
                                                    alignItems: "center", // Center icon and content vertically
                                                    padding: "1px 20px", // Add padding to create some space around the content
                                                    marginBottom:"25px"
                                                }}
                                            >
                                                <div style={{ width: "10%",alignItems:"center", alignContent:"center", justifyContent:"center", display:"flex"  }}>
                                                    <img src={userCircleBlue} style={{width:"30px", height:"30px"}}/>
                                                </div>
                                                <div
                                                    style={{
                                                        width: "90%", // Adjusted width to make space for the close icon
                                                        marginLeft: "10px",
                                                        display:"flex",
                                                        flexDirection:"column"
                                                    }}
                                                >
                                                    <span style={{color:"white", fontWeight:"700"}}>{rdvObj?.custName}</span>
                                                    <span style={{color:"white"}}>{rdvObj?.custAddress}</span>
                                                    <span style={{color:"white"}}>{rdvObj?.custPhone}</span>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            rdvObj?.answers?.length>0 && (
                                                <div style={{width:"100%", display:"flex", justifyContent:"center", marginTop:"32px", marginBottom:"14px"}}>
                                                    <Button onClick={navigateFromToAnswers} disableElevation component="label" className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white"}}>
                                                        <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px", lineHeight:"16px" }}>
                                                            Lire les réponses au questionnaire
                                                        </Typography>
                                                    </Button>
                                                </div>
                                            )
                                        }

                                        <div style={{display:"flex", marginTop:"18px", width:"100%"}}>
                                            <span className="title-style-presta">{rdvObj?.prestaSnapshot?.title}</span>
                                        </div>

                                        <div style={{display:"flex", flexDirection:"row", marginTop:"10px", width:"100%"}}>
                                            <div style={{display: "flex", padding: "5px 10px", alignItems: "center", gap: "10px", borderRadius:"99px", background:"#DAE0E5"}}>
                                                <span style={{color: "#000", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "normal"}}>
                                                    {rdvObj?.prestaSnapshot?.price}
                                                </span>
                                                                        </div>
                                                                        <div style={{display: "flex", padding: "5px 10px", alignItems: "center", gap: "10px", borderRadius:"99px", background:"#DAE0E5", marginLeft:"10px"}}>
                                                <span style={{color: "#000", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "normal"}}>
                                                    {durationFormatMin(rdvObj?.prestaSnapshot?.prestaDuration)}
                                                </span>
                                            </div>
                                        </div>
                                        <div style={{display:"flex", width:"100%", marginTop:"20px"}}>
                                            {rdvObj?.prestaSnapshot?.imageLinks?.length>0 ? <Gallery urls={rdvObj?.prestaSnapshot?.imageLinks}>
                                            </Gallery> : <Gallery urls={[{
                                                imageLink:"https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA..",
                                                vlink:""
                                            }]}>
                                            </Gallery>}

                                        </div>

                                        <span style={{fontSize:"16px", marginTop:"20px", display:"flex", width:"100%", whiteSpace:"pre-line"}}>
                                            {rdvObj?.prestaSnapshot?.description}
                                        </span>


                                        { rdvObj?.prestaSnapshot?.isValidation && !rdvObj?.isValidated ? (<span style={{marginTop:"40px"}}></span>):(
                                            <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
                                                <div className="button-container">
                                                    <Button onClick={handleAddToCalendar} className="button-style button-style-noborders" variant="contained">
                                                        <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                                            Ajouter au calendrier
                                                        </Typography>
                                                    </Button>
                                                </div>
                                                {rdvObj?.start?.seconds * 1000 > Date.now() && !rdvObj?.isRescheduled && (
                                                    <div className="button-container" style={{ marginTop: "15px" }}>
                                                        <Button
                                                            onClick={() => setOpenWarnDialog(true)}
                                                            className="blue-button button-style-noborders"
                                                            variant="contained"
                                                        >
                                                            <Typography
                                                                variant="button"
                                                                style={{ textTransform: 'none', color: '#FFFFFF' }}
                                                            >
                                                                Déplacer le rdv
                                                            </Typography>
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        )}


                                        <div className="button-container" style={{marginTop:"15px"}}>
                                            <Button className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateRdv}>
                                                <Typography variant="button" style={{ textTransform: 'none' }}>
                                                    Retour
                                                </Typography>
                                            </Button>
                                        </div>

                                        <div className="button-container" style={{marginTop:"100px"}}>
                                            <Button style={{marginBottom:"100px"}} className="button-style-nogr button-style-noborders" disableElevation variant="contained" onClick={openDialog}>
                                                <Typography variant="button" style={{ textTransform: 'none', textDecoration: "underline" }}>
                                                    Annuler la réservation
                                                </Typography>
                                            </Button>
                                        </div>
                                    </div>
                                )
                            )


        )}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showBackDrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog open={isDialogOpen} onClose={handleDialogClose}
                    fullWidth={!isLargeScreen}
                    PaperProps={{
                        style: {
                            minWidth: isLargeScreen ? '500px' : 'auto',
                            maxWidth: isLargeScreen ? '500px' : 'auto'
                        },
                    }}>
                <DialogContent style={{backgroundColor:"white"}}>
                    <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                        <span style={{fontSize:"16px", fontWeight:700, marginTop:"30px"}}>Votre motif d'annulation</span>
                        <div style={{display:"flex", width:"100%", flexDirection:"column", paddingTop:"30px", paddingBottom:"20px"}}>
                            {cancelOptions.map((option, index) => (
                                <MenuItem onClick={()=> (handleCancelSelection(option))}
                                          style={{
                                              zIndex:1000,
                                              background:selectedCancel===option
                                                  ? "linear-gradient(150deg, #4808FF 0%, #9747FF 100%)"
                                                  : "#DAE0E5",
                                              marginBottom: "10px",
                                              padding: "10px 15px",
                                              borderRadius:"15px"
                                          }}
                                          key={index}
                                >
                                                <span style={{width: "100%",
                                                    overflow: "hidden",
                                                    color:selectedCancel===option
                                                        ? "white"
                                                        : "black",
                                                    fontWeight:selectedCancel===option
                                                        ? 700
                                                        : 400,
                                                    fontSize:"16px",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis"}}>
                                                    {option}
                                                </span>
                                </MenuItem>
                            ))}

                        </div>
                    </div>
                </DialogContent>
                <DialogActions style={{backgroundColor:"white"}}>
                    <div className="button-container" style={{marginBottom:"30px",marginTop:"6px", marginLeft:"30px", marginRight:"30px"}}>
                        <Button onClick={navigateToCancel} className="button-style button-style-noborders" variant="contained">
                            <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                Enregistrer
                            </Typography>
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>

            <Dialog open={openWarnDialog} onClose={handleWarnDialogCloseOutside}
                    fullWidth={!isLargeScreen}
                    PaperProps={{
                        style: {
                            minWidth: isLargeScreen ? '500px' : 'auto',
                            maxWidth: isLargeScreen ? '500px' : 'auto',
                        },
                    }}
            >
                <DialogContent style={{backgroundColor:"white", maxHeight:"600px", overflowY:"auto"}}>
                    <div style={{display:"flex", width:"100%",flex: 1,
                        flexDirection:"column", paddingTop:"20px"}}>

                        <span><b>⚠️ Important :</b> Pour éviter les abus, une seule modification de date est autorisée par réservation.</span>

                    </div>
                </DialogContent>
                <DialogActions style={{backgroundColor:"white", marginTop:"0"}}>
                    <div className="button-container" style={{marginBottom:"30px",marginTop:"6px", marginLeft:"30px", marginRight:"30px"}}>
                        <Button onClick={() => navigateToAppointment()} className="button-style button-style-noborders" variant="contained">
                            <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                                Continuer
                            </Typography>
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isDepositDialogOpen}
                onClose={handleDepositDialogCloseOutside}
                fullWidth={!isLargeScreen}
                PaperProps={{
                    style: {
                        minWidth: isLargeScreen ? '500px' : 'auto',
                        maxWidth: isLargeScreen ? '500px' : 'auto',
                    },
                }}
            >
                <DialogContent style={{ backgroundColor: "white" }}>
                    <div style={{display:"flex", flexDirection:"column", padding:"10px 6px"}}>
                        <span style={{fontSize:"16px", lineHeight: "20px", marginTop:"20px"}}>
                            Afin d’annuler cette réservation, si un acompte vous a été versé par ce client, nous vous invitons à le rembourser.
                        </span>
                        <span style={{fontSize:"16px", marginTop:"30px", color:"#4808FF", fontWeight:700}}>
                            Option 1. <span style={{color:"black", fontWeight:400}}>En cliquant sur </span><span style={{fontSize:"16px", marginTop:"30px", color:"black", fontWeight:700}}>“Rembourser et annuler” </span><span style={{color:"black", fontWeight:400}}>vous confirmez annuler la réservation et avoir effectuer le remboursement de l’acompte à ce client.</span>

                        </span>

                        <span style={{fontSize:"16px", marginTop:"30px", color:"#4808FF", fontWeight:700}}>
                            Option 2. <span style={{color:"black", fontWeight:400}}>En cliquant sur </span><span style={{fontSize:"16px", marginTop:"30px", color:"black", fontWeight:700}}>“Annuler la réservation” </span><span style={{color:"black", fontWeight:400}}>vous confirmez l’annulation de la réservation et prenez la responsabilité de tout litige avec votre client.</span>
                        </span>
                    </div>
                </DialogContent>
                <DialogActions style={{ backgroundColor: "white", display:"flex", flexDirection:"column", paddingLeft:"16px", paddingRight:"16px" }}>
                    <Button className="blue-button button-style-noborders" style={{marginLeft:"32px", marginRight:"32px", marginBottom:"30px", marginTop:"10px", backgroundColor:"black"}} variant="button"
                            onClick={() => {
                                handleCancelDepClicked(true);
                            }}
                    >
                        <Typography variant="button" style={{textTransform: 'none', color:"white"}}>
                            Rembourser et annuler
                        </Typography>
                    </Button>
                    <Button className="button-style-borders" style={{marginLeft:"32px", marginRight:"32px", marginBottom:"40px", marginTop:"10px", backgroundColor:"white"}} variant="button"
                            onClick={() => {
                                handleCancelDepClicked(false);
                            }}
                    >
                        <Typography variant="button" style={{textTransform: 'none', color:"black", fontSize:"18px", fontWeight:400}}>
                            Annuler la réservation
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>

            <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar}
                                          message={snackMessage} style={{width: "100%"}}/>
      </div>
    )
}