import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import Button from "@mui/material/Button";
import {Chip, InputAdornment, Skeleton, TextField, Typography} from "@mui/material";
import firebase from "firebase/compat/app";
import {HeaderHome} from "./headerHome";
import icDiamond from "../assets/diamondColored.svg";
import {getCustomers, getPrestaStats, getUserBlocs, getUserSubscription} from "../firebase";
import SnackbarWithCustomBackground from "./snackbar";
import {FaSearch} from "react-icons/fa";
import calendarCheckGreyIc from "../assets/calendarCheckGrey.svg";
import timeCheckGrey from "../assets/timeCheckGrey.svg";

export default function Prestations() {
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const [showSkeleton, setShowSkeleton] = useState(true);
    const [isSub, setIsSub] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [queryResult, setQueryResult] = useState([]);
    const fallBackUrl = "https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA..";

    const handleImageLoad = (event) => {
        event.target.classList.add('loaded'); // Add the 'loaded' class to trigger fade-in
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(async (user) => {
            if (!user) {
                navigate("/");
            } else {
                const fetchData = async () => {
                    try {
                        const subObj = await getUserSubscription(user.uid);
                        if (subObj?.status==="trialing" || subObj?.status==="active"){
                            setIsSub(true);
                            const response = await getPrestaStats();
                            setQueryResult(response);
                            setShowSkeleton(false);
                        } else {
                            setIsSub(false);
                            setShowSkeleton(false);
                        }
                    } catch (e) {
                        console.error(e);
                        handleOpenSnackbar("Quelque chose s'est mal passé, actualisez la page ou contactez nous. "+e.message);
                        setShowSkeleton(false);
                    }
                }
                fetchData();
            }
        });
        // Cleanup the observer when the component is unmounted
        return () => {
            unregisterAuthObserver();
        }
    }, []);

    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };
    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };
    const navigateHome = () => {
        navigate(-1);
    };

    const filteredAndSortedResults = useMemo(() => {
        const lowerQuery = searchQuery.trim().toLowerCase();
        return queryResult
            .filter((doc) => {
                return (
                    lowerQuery === '' ||
                    doc.title.toLowerCase().includes(lowerQuery)
                );
            })
            .sort((a, b) =>  b.count - a.count);
    }, [queryResult, searchQuery]);

    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            {showSkeleton ?
                (
                    <div className="sub-container-no-top">
                        <div style={{
                            display: "flex", width: "100%", flexDirection: "column",
                            justifyContent: "center", alignContent: "center", alignItems: "center"
                        }}>
                            <Skeleton style={{borderRadius: "15px"}} variant="text" sx={{fontSize: '2rem'}}
                                      width="70%"/>
                            <Skeleton style={{marginTop: "50px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                            <Skeleton style={{marginTop: "25px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                            <Skeleton style={{marginTop: "25px", borderRadius: "15px"}} variant="rectangular"
                                      width="100%" height={150}/>
                        </div>
                    </div>
                ) : (

                    isSub ? (
                        <div className="sub-container-no-top">
                            <span className="title-style">Prestations</span>
                            <div className="field-container" style={{marginTop:"30px", display:"flex", flexDirection:"row", alignContent:"top", alignItems:"top"}} >
                                <div className="title-field-container" style={{width:"70%"}}>
                                          <span className="text-field-style">
                                              Prestations
                                          </span>
                                </div>
                            </div>
                            <div style={{display:"flex", flexDirection:"row", width:"100%"}}>
                                <TextField style={{width:"100%"}} className="field-style" id="outlined-basic" label="Rechercher" variant="outlined" value={searchQuery}
                                           onChange={(e) => setSearchQuery(e.target.value)}
                                           InputProps={{
                                               endAdornment: (
                                                   <InputAdornment position="end">
                                                       <FaSearch style={{color:"black"}} />
                                                   </InputAdornment>
                                               ),
                                           }}/>

                            </div>
                            <div style={{display:"flex", flexDirection:"column", borderRadius:"15px", alignContent:"center", justifyContent:"center",
                                alignItems:"center", background: "linear-gradient(90deg, rgba(255, 8, 68, 0.2) 0%, rgba(72, 8, 255, 0.2) 100%)",
                                height:"44px", width:"80%", marginTop:"40px"}}>
                                    <span>
                                        Résultats: <b>{queryResult.filter((doc)=> {
                                        const lowerQuery = searchQuery.trim().toLowerCase();
                                        return lowerQuery === '' ||
                                            doc.title.toLowerCase().includes(lowerQuery)
                                    }).length}</b>  sur {queryResult.length}
                                    </span>
                            </div>
                            <div className="field-container" style={{marginTop:"30px", display:"flex", flexDirection:"row", alignContent:"top", alignItems:"top"}} >
                                <div className="title-field-container" style={{width:"70%"}}>
                                          <span className="text-field-style">
                                              Liste des prestations
                                          </span>
                                </div>
                            </div>
                            <div style={{ maxHeight: '400px', overflowY: 'scroll', padding:"12px", width:"95%"}}>
                                {queryResult.filter((doc)=> {
                                    const lowerQuery = searchQuery.trim().toLowerCase();
                                    return lowerQuery === '' ||
                                        doc.title.toLowerCase().includes(lowerQuery);
                                }).sort((a, b) => b.count - a.count
                                ).map((doc, index)=> (
                                    <div key={index+1}
                                         style={{
                                             display: "flex",
                                             background: "#FFF" ,
                                             marginTop: "23px",
                                             minHeight: "10px",
                                             borderRadius: "15px",
                                             boxShadow: "0px 2px 11px 0px rgba(0, 0, 0, 0.25)",
                                             width: "100%",
                                             flexDirection: "column",
                                         }}
                                    >
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            marginTop: "25px",
                                            marginRight: "5px"
                                        }}>
                                        </div>
                                        <div
                                            style={{
                                                color: "inherit",
                                                display: "flex",
                                                flexDirection: "row", // Change to row to have icon and content side by side
                                                alignItems: "center", // Center icon and content vertically
                                                padding: "1px 20px", // Add padding to create some space around the content
                                                marginBottom: "15px"
                                            }}
                                        >
                                            <div style={{
                                                width: "30%",
                                                alignItems: "center",
                                                alignContent: "center",
                                                justifyContent: "center",
                                                display: "flex"
                                            }}>
                                                <img onLoad={handleImageLoad}
                                                     onError={(e) => {
                                                         if (e.target.src!==fallBackUrl){
                                                             e.target.onerror = null;
                                                             e.target.src= fallBackUrl
                                                         }
                                                     }}
                                                     className="fade-in" style={{
                                                    color: "white",
                                                    borderRadius: "5px",
                                                    width: "60px",
                                                    height: "60px",
                                                    objectFit: "cover",
                                                }}
                                                     src={doc?.imageLinks?.length > 0 ? doc?.imageLinks[0]?.imageLink : "https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/image_prestation_par_dfaut.png?alt=media&token=4cb8ec1c-7417-4d93-aa3d-15bc9d0fda0e&_gl=1*1tjyqyy*_ga*OTc3Mjg1MTE0LjE2ODgyMDM2NTU.*_ga_CW55HF8NVT*MTY5NzA0MDcyNy4zMTguMS4xNjk3MDQwODI3LjI4LjAuMA.."}/>
                                            </div>
                                            <div
                                                style={{
                                                    width: "70%", // Adjusted width to make space for the close icon
                                                    marginLeft: "10px",
                                                    marginRight: "50px",
                                                }}
                                            >
                                            <span style={{
                                                color: "black",
                                                fontWeight: "700",
                                                fontSize: "16px"
                                            }}>{doc.title}</span>
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row", // Change to row to have icon and content side by side
                                                alignItems: "center", // Center icon and content vertically
                                                padding: "6px 20px", // Add padding to create some space around the content
                                                marginBottom: "15px"
                                            }}
                                        >
                                            <div style={{
                                                width: "10%",
                                                alignItems: "center",
                                                alignContent: "center",
                                                justifyContent: "center",
                                                display: "flex"
                                            }}>
                                                <img src={calendarCheckGreyIc} style={{width: "30px", height: "30px"}}/>
                                            </div>
                                            <div
                                                style={{
                                                    width: "90%",
                                                    marginLeft: "10px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignContent: "center",
                                                    justifyContent: "center"
                                                }}
                                            >
                                                <span><b>{doc?.count}</b> Réservations</span>
                                        </div>
                                    </div>
                                    </div>
                                    )
                                )}
                            </div>

                            {
                                filteredAndSortedResults.length===0 && (
                                    <span style={{marginTop:"20px"}}>Aucun résultat</span>
                                )
                            }

                            <div className="button-container" style={{marginTop: "70px"}}>
                                <Button style={{marginBottom: "100px"}}
                                        className="button-style-nogr button-style-borders"
                                        disableElevation variant="contained" onClick={navigateHome}>
                                    <Typography variant="button" style={{textTransform: 'none'}}>
                                        Retour
                                    </Typography>
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className="sub-container-no-top">
                            <span className="title-style">Prestations</span>
                            <div style={{
                                display: "flex", borderRadius: "15px", width: "100%",
                                boxShadow: "0px 4px 15px 0px rgba(218, 224, 229, 0.9)",
                                lineHeight: "20px", marginTop: "40px"
                            }}>
                            <span style={{
                                marginBottom: "20px",
                                marginLeft: "20px",
                                marginRight: "20px",
                                marginTop: "15px"
                            }}>
                               Cette fonctionnalité n’est pas disponible en forfait gratuit, nous vous invitons à passer au forfait Premium pour pouvoir l’utiliser.
                            </span>
                            </div>
                            <div style={{
                                width: "100%",
                                justifyContent: "center",
                                display: "flex",
                                marginBottom: "40px"
                            }}>
                                <Chip onClick={() => navigate("/subscription")}
                                      icon={<img style={{width: "16px", height: "16px"}} src={icDiamond}></img>}
                                      style={{
                                          marginTop: "20px",
                                          background: "white",
                                          cursor: "pointer",
                                          border: "1px solid black",
                                          color: "black",
                                          fontWeight: "400",
                                          padding: "18px 18px",
                                          fontSize: "14px",
                                          borderRadius: "1000px"
                                      }}
                                      label="Faismoibg premium">
                                </Chip>
                            </div>

                            <div style={{height:"375px"}}>
                                <img onLoad={handleImageLoad} width={300}
                                     className="fade-in" src="https://firebasestorage.googleapis.com/v0/b/faismoibg-app.appspot.com/o/NODELETE%2Fprestations.png?alt=media&token=a5e7f991-dcd4-45e9-9022-518bd9f30c61"/>
                            </div>

                            <div className="button-container" style={{marginTop: "70px"}}>
                                <Button style={{marginBottom: "100px"}}
                                        className="button-style-nogr button-style-borders"
                                        disableElevation variant="contained" onClick={navigateHome}>
                                    <Typography variant="button" style={{textTransform: 'none'}}>
                                        Retour
                                    </Typography>
                                </Button>
                            </div>
                        </div>
                    )
                )}
            <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar}
                                          message={snackMessage} style={{width: "100%"}}/>
        </div>
    )
}