import React, {useEffect, useState} from "react";
import {
    Autocomplete, Backdrop, Chip, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Skeleton,
    TextField,
    Typography, useMediaQuery, useTheme
} from "@mui/material";
import {
    FaEyeSlash
} from "react-icons/fa6";
import Button from "@mui/material/Button";
import {FaCheckCircle, FaCoffee, FaEye, FaTimes} from "react-icons/fa";
import {useLocation, useNavigate} from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/firestore'
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import {
    changeUserEmail,
    checkIfEmailExists, getSmsData,
    getUser,
    getUserPostCreateStatus, getUserSubscription,
    logout, resetPassword, updateSmsDocument,
    updateUserDocument
} from "../firebase";
import SnackbarWithCustomBackground from "./snackbar";
import AutocompleteAddressFrance from "./addressac";
import IconButton from "@mui/material/IconButton";
import pinMapGrey from "../assets/ph_map-pin.svg";
import diamondIc from "../assets/diamondColored.svg";
import icCoffee from "../assets/icCoffee.svg";
import {HeaderHome} from "./headerHome";
import trashic from "../assets/trashic.svg";
import {IOSSwitch} from "./iosswitch";
import icDiamond from "../assets/diamondColored.svg";

const validateEmail = (email) => {
    // Basic email validation using a regular expression
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
};

function isValidFrenchPhoneNumber(phoneNumber) {
    // Remove any non-digit characters from the input
    const cleanNumber = phoneNumber.replace(/\D/g, '');

    // Check if the number starts with "33" or "0" followed by nine digits
    const isValidFormat = /^(33|0\d{9})$/.test(cleanNumber);

    if (isValidFormat) {
        // If the number starts with "33", replace it with "0"
        const normalizedNumber = cleanNumber.startsWith('33') ? `0${cleanNumber.slice(2)}` : cleanNumber;
        return { isValid: true, phoneNumber: normalizedNumber };
    } else {
        return { isValid: false, phoneNumber: null };
    }
}

const timezones = [
    'Europe/Paris',              // France (CEST)
    'America/Martinique',       // Martinique (AST)
    'America/Guadeloupe',       // Guadeloupe (AST)
    'America/Cayenne',          // French Guiana (GFT)
    'America/Port_of_Spain',    // Saint Martin (AST)
    'America/Montreal',         // French-speaking Canada (Eastern Time)
    'Europe/London'             // United Kingdom (GMT)
];

export default function Settings() {

    const { pathname } = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    //Screen media query for dialog width
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const remAddr = firebase.functions().httpsCallable('remAddr');

    useEffect( () => {
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged( async(user) => {
            if (!user) {
                navigate("/");
            } else {
                setShowBackdrop(true);
                const fetchData = async () => {
                    try {
                        const postCreateDone = await getUserPostCreateStatus(user.uid);
                        return postCreateDone;
                    } catch (error) {
                        console.error('Error retrieving postCreateDone status:', error);
                    }
                };
                // Call the fetchData function directly
                const displayPage = await fetchData(user.uid);
                if (!displayPage){
                    //User needs to setup minimal info before seeing this page
                    setShowBackdrop(false);
                    navigate("/info" , { replace: true });
                } else {
                    //fetch page data
                    const currentUser = firebase.auth().currentUser;

                    if (currentUser) {
                        // Check if the user's provider is Google
                        const providerData = currentUser.providerData;
                        const isGoogleProvider = providerData.some(
                            (provider) => provider.providerId === 'google.com'
                        );

                        setIsGoogleAuth(isGoogleProvider);
                    } else {
                        setIsGoogleAuth(false); // User is not authenticated
                    }
                    async function fetchUserData() {
                        try {
                            const userData = await getUser(user?.uid);
                            const subObj = await getUserSubscription(firebase.auth().currentUser.uid);
                            const smsData = await getSmsData(user.uid);
                            if (smsData){
                                setBrevoApiKey(smsData.apiKey);
                                setIsBrevoEnabled(smsData.isBrevoEnabled);
                            }
                            if (subObj?.status){
                                setSubStatus(subObj.status);
                            }
                            setUserObject({...userData});
                            setFormData({
                                ...formData,
                                email: userData?.email || "",
                                nom : userData?.nom || "",
                                address: userData?.address || [],
                                phone: userData?.phone || "",
                                prenom: userData?.prenom || "",
                                timezone: userData?.timezone || "Europe/Paris"
                            });
                        } catch (e){
                            handleOpenSnackbar("Ooops quelque chose s'est mal passé, contactez nous et communiquez cette erreur => ", e);
                        }
                        setShowBackdrop(false);
                    }
                    await fetchUserData();
                }
            }
        });
        // Cleanup the observer when the component is unmounted
        return () => unregisterAuthObserver();
    }, []);

    //Stateful vars
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [userObject, setUserObject] = useState({});
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDialogPassOpen, setIsDialogPassOpen] = useState(false);
    const [tempAddress, setTempAddress] = useState("");
    const [isWarnDialogOpen, setIsWarnDialogOpen] = useState(false);
    const [indexToDelete, setIndexToDelete] = useState(null);
    const [isGoogleAuth, setIsGoogleAuth] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [subStatus, setSubStatus] = useState("");
    const [isBrevoEnabled, setIsBrevoEnabled] = useState(false);
    const [brevoApiKey, setBrevoApiKey] = useState("");
    const [isAPIDialogOpen, setIsAPIDialogOpen] = useState(false);
    const [tempBrevoApiKey, setTempBrevoApiKey] = useState("");

    let password = "";

    const [formData, setFormData] = useState({
        email : "", nom : "", prenom : "", phone : "", address : [], timezone: "Europe/Paris"
    });

    useEffect(() => {
    }, [showSnackbar]);

    //Handle form change
    const handleFormChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });

        console.log("name ", event.target.name, " tz => ",  event.target.value)
    };

    const handleTzChange = (event, newval) => {
        setFormData({
            ...formData,
            timezone: newval
        });

    };


    //Handle submit
    const handleSubmit = async () => {
        setShowBackdrop(true);
        if (userObject.email!==formData.email && password.length<6){
            setIsDialogPassOpen(true);
            return;
        }

        const phoneObj = isValidFrenchPhoneNumber(formData.phone);
        const isEmpty = formData.phone.length<1;
        if (!validateEmail(formData.email) && !isGoogleAuth){
            handleOpenSnackbar("Vérifiez votre nouvelle adresse email, celle-ci ne semble pas valide.");
        } else if (!isEmpty && !phoneObj.isValid){
            handleOpenSnackbar("Vérifiez votre numéro de téléphone, celui-ci ne semble pas valide.");
        } else if (!isGoogleAuth &&  userObject.email!==formData.email && await checkIfEmailExists(formData.email)){
            handleOpenSnackbar("Cette adresse email ne peut pas être utilisée :/");
        } else {
            if (userObject.email!==formData.email && password.length>6){
                try {
                    await changeUserEmail(formData.email, password);
                } catch (e){
                    handleOpenSnackbar("Quelque chose s'est mal passé lors du changement de votre email, réessayez ou contactez nous.");
                    console.log(e.message);
                    setShowBackdrop(false);
                    return;
                }
            }
            let dataToUpdate = {};
            if (isGoogleAuth){
                dataToUpdate = {
                    nom: formData.nom,
                    prenom: formData.prenom,
                    phone: formData.phone,
                    address: formData.address,
                    timezone:formData.timezone
                };
            } else {
                dataToUpdate = {
                    email: formData.email,
                    nom: formData.nom,
                    prenom: formData.prenom,
                    phone: formData.phone,
                    address: formData.address,
                    timezone:formData.timezone
                };
            }

            const smsObject = {
                isBrevoEnabled: isBrevoEnabled,
                apiKey: brevoApiKey,
                timestamp: new Date(),
                uid: firebase.auth().currentUser.uid
            }

            try {
                await updateUserDocument(firebase.auth().currentUser.uid, dataToUpdate);
                await updateSmsDocument(firebase.auth().currentUser.uid, smsObject)
                for (let addr of userObject.address){
                    if (!dataToUpdate.address.includes(addr)){
                        console.log("address is no longer here ", addr);
                        const result = await remAddr({ addrString : addr
                        });
                        console.log("Removed successfully ", result?.data?.success);
                    }
                }

                setShowBackdrop(false);
                navigate("/home" , { replace: true });
            } catch (e){
                setShowBackdrop(false);
                console.log(e.message);
                handleOpenSnackbar("Quelque chose s'est mal passé :/ Contactez nous ", e.message);
            }

        }
        setShowBackdrop(false);
    };

    //Handle address selected
    const handleAddressSelected = (selectedAddress) => {
        if (selectedAddress?.length>0){
            setTempAddress(selectedAddress);
        }
    };

    //Navigation
    const navigateToHome = () => {
        navigate("/home");
    };

    const navigateToSub = () => {
        navigate("/subscription");
    };

    //Handle dialog
    const handleDialogOpen = () => {
        setIsDialogOpen(true);
    };

    const handleAPIKEYChange = (e) => {
        setTempBrevoApiKey(e.target.value.trim());
    };

    //Handle dialog
    const handleSaveApiKey = (e) => {
        if (tempBrevoApiKey?.length>10){
            setBrevoApiKey(tempBrevoApiKey);
            setIsAPIDialogOpen(false);
            handleOpenSnackbar("Clé API ajoutée avec succès. Vous pouvez désormais activer l'intégration Brevo.");
        } else {
            setBrevoApiKey("");
            setIsAPIDialogOpen(false);
            setIsBrevoEnabled(false);
            handleOpenSnackbar("La clé API n'est pas valide.");
        }
    };


    // Function to handle the address deletion
    const handleDeleteAddress = () => {
        // Create a copy of the address array to modify it without mutating the state directly
        const updatedAddresses = [...formData.address];
        updatedAddresses.splice(indexToDelete, 1); // Remove the address at the specified index

        // Update the state with the modified address array
        setFormData({
            ...formData,
            address: updatedAddresses,
        });
        setIsWarnDialogOpen(false);
    };

    const handleAddressCloseOutside = (event) => {
        setIsDialogOpen(false);
    };

    const handleAPICloseOutside = (event) => {
        setIsAPIDialogOpen(false);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        if (tempAddress?.length>0 && !formData.address.includes(tempAddress)){
            setFormData({
                ...formData,
                address: [...formData.address, tempAddress]
            });
            setTempAddress("");
        } else {
            setTempAddress("");
        }
    };


    // Function to open the dialog when FaTimes is clicked
    const handleWarnDialogOpen = (index) => {
        setIndexToDelete(index);
        setIsWarnDialogOpen(true);
    };

    // Function to close the dialog when close button is clicked
    const handleWarnDialogClose = () => {
        setIsWarnDialogOpen(false);
        setIndexToDelete(null); // Reset the indexToDelete state if the dialog is closed without deletion
    };

    //SnackBar handling
    const handleOpenSnackbar = (message) => {
        setSnackMessage(message)
        setShowSnackbar(true);
    };

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
    };

    const handleLogOut = async () => {
        try {
            await logout();
        } catch (e){
            console.log(e);
            handleOpenSnackbar("Ooops quelque chose s'est mal passé, contactez nous => ", e);
        }
    }

    const handleIconEyeClick = () => {
        setShowPassword(!showPassword);
    }

    const handlePasswordChange = (event) => {
        password = event.target.value;
    };

    const handlePassDialogClose = async (event) => {
        setIsDialogPassOpen(false);
        if (password.length>6){
            await handleSubmit();
        } else {
            handleOpenSnackbar("Votre mot de passe doit contenir plus de 6 caractères")
        }
    };

    const handlePassCloseOutside = (event) => {
        setIsDialogPassOpen(false);
    };

    const handleEnableBrevo = () => {
        if (!isBrevoEnabled && brevoApiKey?.length<10){
            handleOpenSnackbar("Vous devez d'abord entrer une clé API pour activer ce service.")
        } else {
            setIsBrevoEnabled(!isBrevoEnabled)
        }
    };

    const handleSendReset = async () => {
        if (validateEmail(userObject.email)){
            const success = await resetPassword(userObject.email);
            if (success) {
                handleOpenSnackbar("Un lien vous a été envoyé");
            } else {
                handleOpenSnackbar("Quelque chose s'est mal passé");
            }
        } else {
            handleOpenSnackbar("Cette adresse email n'est pas valide.");
        }
    }


    return (
        <div className="sub-container">
            <HeaderHome></HeaderHome>
            {Object.keys(userObject).length === 0 ? (
                <div className="sub-container-no-top">
                    <div style={{display:"flex", width:"100%", flexDirection:"column",
                        justifyContent:"center", alignContent:"center", alignItems:"center"}}>
                        <Skeleton style={{borderRadius:"15px"}} variant="text" sx={{ fontSize: '2rem' }} width="70%" />
                        <Skeleton style={{marginTop:"50px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                        <Skeleton style={{marginTop:"25px", borderRadius:"15px"}} variant="rectangular" width="100%" height={150}/>
                    </div>
                </div>
                ) : (
            <div className="sub-container-no-top">
                <span className="title-style">Paramètres du compte</span>

                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Nom
                                  </span>
                    </div>
                    <TextField onChange={handleFormChange} value={formData?.nom} className="field-style"
                               label="Ecrire" variant="outlined" name="nom" />
                </div>

                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Prénom
                                  </span>
                    </div>
                    <TextField onChange={handleFormChange} value={formData?.prenom} className="field-style"
                               label="Ecrire"  variant="outlined" name="prenom" />
                </div>

                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Téléphone
                                  </span>
                    </div>
                    <TextField onChange={handleFormChange} value={formData?.phone} className="field-style"
                               label="Ecrire" variant="outlined" name="phone" inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}/>
                </div>

                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Email <span className="aster">*</span>
                                  </span>
                    </div>
                    <TextField autoComplete="no" disabled={isGoogleAuth} onChange={handleFormChange} value={formData.email} className="field-style"
                               label="Ecrire" variant="outlined" name="email" />
                </div>

                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Fuseau horaire
                                  </span>
                    </div>
                    <div style={{width:"100%"}}>
                        <Autocomplete
                            disableClearable={true}
                            value={formData.timezone}
                            onChange={handleTzChange}
                            PopperComponent={(props) => (
                                <div style={{display:"flex", flexDirection:"column"}}>
                                    {props.children}
                                </div>
                            )}
                            options={timezones}
                            renderInput={({ inputProps, ...params }) => (
                                <TextField {...params}
                                           label="Timezone"
                                           inputProps={{ ...inputProps, readOnly: true }}
                                           placeholder="Timezone" />
                            )}
                            getOptionLabel={(option) => option}
                            fullWidth
                            componentsProps={{
                                popper: {
                                    modifiers: [
                                        {
                                            name: 'flip',
                                            enabled: false
                                        },
                                        {
                                            name: 'preventOverflow',
                                            enabled: false
                                        }
                                    ]
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Ajouter une ou plusieurs adresses
                                  </span>
                    </div>
                </div>

                <div style={{ width: "90%", display: "flex", flexDirection: "column" }}>
                    {Array.isArray(formData.address) ? (
                        formData.address.map((addressValue, index) => (
                            addressValue.length>0 && (
                                    <div
                                        key={index}
                                        style={{
                                            display: "flex",
                                            backgroundColor: "white",
                                            marginTop: "15px",
                                            borderRadius: "15px",
                                            boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.25)",
                                            width: "100%",
                                            flexDirection: "column", // Change to column to stack items vertically
                                        }}
                                    >
                                        <div style={{ display: "flex", justifyContent: "flex-end", marginTop:"5px", marginRight:"5px" }}>
                                            <IconButton
                                                className="iconbutton-noborders"
                                                size="small"
                                                onClick={() => handleWarnDialogOpen(index)} //
                                            >
                                                {/*<FaTimes style={{color:"black"}}
                        />*/}
                                                <img src={trashic} style={{width:"20px", height:"20px"}}/>


                                            </IconButton>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row", // Change to row to have icon and content side by side
                                                alignItems: "center", // Center icon and content vertically
                                                padding: "1px 20px", // Add padding to create some space around the content
                                                marginBottom:"25px"
                                            }}
                                        >
                                            <div style={{ width: "10%" }}>
                                                <img src={pinMapGrey} style={{width:"30px", height:"30px"}}/>
                                            </div>
                                            <div
                                                style={{
                                                    width: "90%", // Adjusted width to make space for the close icon
                                                    marginLeft: "10px",
                                                }}
                                            >
                                                <span>{addressValue}</span>
                                            </div>
                                        </div>
                                    </div>
                                )

                        ))
                    ) : (
                        <div>Aucune adresse.</div>
                    )}
                </div>

                <div style={{ color:"white", display:"flex", alignSelf:"start", alignContent:'start', alignItems:'start', justifyContent:'center', marginTop:"20px"}}>
                    <Button  disableElevation onClick={handleDialogOpen} className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white"}}>
                        <Typography variant="button" style={{ textTransform: 'none', marginLeft:"2px", marginRight:"2px", fontSize:"14px" }}>
                            Ajouter une nouvelle adresse
                        </Typography>
                    </Button>
                </div>

                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      SMS de rappel via Brevo
                                  </span>
                    </div>
                </div>

                {
                    subStatus==="trialing" || subStatus==="active" ? (
                        <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
                            <span style={{
                                textAlign: "start",
                                width: "100%",
                                marginTop: "8px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                            }}>{brevoApiKey?.length>10 ? brevoApiKey : "Aucune clé API"}</span>
                            <div style={{ color:"white", display:"flex", alignSelf:"start", alignContent:'start', alignItems:'start', justifyContent:'center', marginTop:"20px"}}>
                                <Button onClick={()=>setIsAPIDialogOpen(true)} disableElevation className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white"}}>
                                    <Typography variant="button" style={{ textTransform: 'none', marginLeft:"2px", marginRight:"2px", fontSize:"14px" }}>
                                        Ajouter une clé API Brevo
                                    </Typography>
                                </Button>
                            </div>

                            <div style={{ marginTop:"20px" ,width:"100%", display:"flex", flexDirection:"row", justifyContent:"start", alignItems:"center"}}>
                                <IOSSwitch
                                    checked={isBrevoEnabled}
                                    onChange={(event) => handleEnableBrevo(event)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />

                                {isBrevoEnabled? <span style={{marginLeft:"20px", color:"black"}}>Activé</span>
                                    : <span style={{marginLeft:"20px", color:"black"}}>Desactivé</span>}
                            </div>

                        </div>
                    ) : (
                        <div style={{width:"100%", display:"flex", flexDirection:"column"}}>
                            <div style={{display:"flex", borderRadius:"15px", width:"100%",
                                boxShadow: "0px 4px 15px 0px rgba(218, 224, 229, 0.9)",
                                lineHeight:"20px", marginTop:"20px"}}>
                                    <span style={{marginBottom: "20px", marginLeft:"20px", marginRight:"20px",marginTop:"15px"}}>
                                        Cette fonctionnalité n’est pas disponible en forfait gratuit, nous vous invitons à passer au forfait Premium pour pouvoir l’utiliser.
                                    </span>
                            </div>
                            <div style={{width:"100%", justifyContent:"start", display:"flex"}}>
                                <Chip onClick={()=>navigate("/subscription")}
                                      icon={<img style={{width:"16px", height:"16px"}} src={icDiamond}></img>}
                                      style={{marginTop:"20px", background:"white", cursor:"pointer", border:"1px solid black",
                                          color:"black", fontWeight:"400", padding:"18px 18px", fontSize:"14px", borderRadius:"1000px"}}
                                      label="Faismoibg premium">
                                </Chip>
                            </div>
                        </div>
                    )
                }


                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Mot de passe
                                  </span>
                    </div>
                </div>

                <div style={{ color:"white", display:"flex", alignSelf:"start", alignContent:'start', alignItems:'start', justifyContent:'center', marginTop:"10px"}}>
                    <Button onClick={handleSendReset}  disableElevation disabled={isGoogleAuth} className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white"}}>
                        <Typography variant="button" style={{ textTransform: 'none', marginLeft:"2px", marginRight:"2px", fontSize:"14px" }}>
                            Réinitialiser votre mot de passe
                        </Typography>
                    </Button>
                </div>

                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Connexion {isGoogleAuth ? "avec Google" : "via email"}
                                  </span>
                    </div>
                </div>

                <div style={{display:"flex",backgroundColor:"white", marginTop:"10px", borderRadius:"15px", boxShadow:"0px 4px 15px 0px rgba(0, 0, 0, 0.25)", width:"100%", flexDirection:"row"}}>

                    <div style={{width:"100%", margin:"20px"}}>
                        <span style={{fontWeight:"700"}}>Connexion au compte {isGoogleAuth ? "Google" : "via email"}</span>
                        <div style={{marginTop:"16px"}}>
                      <span style={{color:"#2F2F2F"}}>
                          {userObject.email}
                      </span>
                        </div>
                    </div>
                    <div style={{width:"10%", padding:"20px", alignContent:"center", alignItems:"center", justifyContent:"center", display:"flex"}}>
                        <FaCheckCircle style={{fontSize:"1.9rem", color:"#02c39a"}}></FaCheckCircle>
                    </div>
                </div>

                {/*<div style={{ color:"white", display:"flex", alignSelf:"start", alignContent:'start', alignItems:'start', justifyContent:'center', marginTop:"20px"}}>
                    <Button disableElevation onClick={handleLogOut} className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white"}}>
                        <Typography variant="button" style={{ textTransform: 'none', marginLeft:"2px", marginRight:"2px", fontSize:"14px" }}>
                            Se déconnecter
                        </Typography>
                    </Button>
                </div>*/}

                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Mentions légales et confidentialité
                                  </span>
                    </div>
                </div>
                <div className="title-field-container">
                    <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
                          <a style={{fontSize:"16px",textDecoration:"underline", cursor:"pointer"}} href="https://shine.faismoibg.com/politique-confidentialite" target="_blank">
                              https://shine.faismoibg.com/politique-confidentialite
                          </a>
                          <a style={{fontSize:"16px",textDecoration:"underline", cursor:"pointer",  marginTop:"10px"}} href="https://shine.faismoibg.com/cgu" target="_blank">
                                  https://shine.faismoibg.com/cgu
                          </a>
                          <a style={{fontSize:"16px",textDecoration:"underline", cursor:"pointer",  marginTop:"10px"}} href="https://shine.faismoibg.com/mentions-legales" target="_blank">
                                  https://shine.faismoibg.com/mentions-legales
                          </a>
                    </div>

                </div>

                <div className="field-container">
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Statut du compte faismoibg
                                  </span>
                    </div>
                </div>
                <div style={{display:"flex", alignSelf:"start", flexDirection:"column"}}>
                    {subStatus === "trialing" || subStatus==="active" ? (
                        <div style={{display:"flex",backgroundColor:"white", marginTop:"10px", borderRadius:"15px", boxShadow:"0px 4px 15px 0px rgba(0, 0, 0, 0.25)", width:"100%", flexDirection:"column"}}>
                            <div style={{margin:"20px", display:"flex", flexDirection:"row", alignItems:"center"}}>
                                <span>
                                    Forfait premium
                                </span>
                                <img src={diamondIc} style={{ width:"40px", height:"40px", marginLeft:"20px"}}></img>
                            </div>
                        </div>
                        ) : (
                        <div style={{display:"flex",backgroundColor:"white", marginTop:"10px", borderRadius:"15px", boxShadow:"0px 4px 15px 0px rgba(0, 0, 0, 0.25)", width:"100%", flexDirection:"column"}}>
                            <div style={{marginTop:"20px",marginLeft:"20px",marginRight:"20px", display:"flex", flexDirection:"row", alignItems:"center"}}>
                                <span>
                                    Forfait gratuit
                                </span>
                                <img src={icCoffee} style={{ width:"40px", height:"40px", marginLeft:"20px"}}></img>
                            </div>
                            <div style={{display:"flex", alignSelf:"start", marginBottom:"20px",marginLeft:"20px",marginRight:"20px",}}>
                                <Button disableElevation={true} onClick={() => navigateToSub()}
                                        className="button-style-noborders-lil" variant="contained" style={{backgroundColor:"black", color:"white", marginTop:"10px"}}>
                                    <Typography variant="button" style={{ textTransform: 'none', fontSize:"14px"}}>
                                        S'abonner
                                    </Typography>
                                </Button>
                            </div>
                        </div>
                        )
                    }
                </div>

                <div className="field-container" style={{}}>
                    <div className="title-field-container">
                                  <span className="text-field-style">
                                      Supprimer ce compte
                                  </span>
                    </div>
                    <div className="title-field-container">
                              <span style={{fontSize:"16px"}}>
                                  Notre système de suppression est en cours de développement. Envoyez votre demande par email afin que nous y procédions manuellement.
                                  <br></br><br></br>faismoibg@gmail.com
                              </span>
                    </div>
                </div>

                <div className="button-container" style={{marginTop:"70px"}}>
                    <Button onClick={handleSubmit} className="button-style button-style-noborders" variant="contained">
                        <Typography variant="button" style={{ textTransform: 'none', color:'#FFFFFF' }}>
                            Enregistrer
                        </Typography>
                    </Button>
                </div>

                <div className="button-container" style={{marginTop:"15px"}}>
                    <Button style={{marginBottom:"100px"}} className="button-style-nogr button-style-borders" disableElevation variant="contained" onClick={navigateToHome}>
                        <Typography variant="button" style={{ textTransform: 'none', fontWeight:"400" }}>
                            Annuler
                        </Typography>
                    </Button>
                </div>

                {/*<div className="button-container" style={{marginTop:"15px"}}>
                    <Button style={{marginBottom:"100px"}} className="button-style-nogr button-style-noborders" disableElevation variant="contained" onClick={handleLogOut}>
                        <Typography variant="button" style={{ textTransform: 'none', textDecoration: "underline" }}>
                            Se déconnecter
                        </Typography>
                    </Button>
                </div>*/}

                <Dialog open={isDialogOpen} onClose={handleAddressCloseOutside}
                        fullWidth={!isLargeScreen}
                        PaperProps={{
                            style: {
                                minWidth: isLargeScreen ? '500px' : 'auto',
                                maxWidth: isLargeScreen ? '500px' : 'auto'
                            },
                        }}
                >

                    <DialogContent style={{backgroundColor:"white"}}>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <span style={{fontWeight:700, fontSize:"16px"}}>
                                Séléctionner une adresse
                            </span>
                            <div style={{display:"flex", flexDirection:"column", marginTop:"12px" }}>
                                <AutocompleteAddressFrance onAddressSelected={handleAddressSelected} />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions style={{backgroundColor:"white" }}>
                        <div style={{width:"100%", display:"flex", alignItems:"end", justifyContent:"end"}}>
                            <Button className="button-style-noborders-lil" style={{marginRight:"6px", marginBottom:"6px", backgroundColor:"black"}}
                                    variant="button" onClick={handleDialogClose}>
                                <Typography variant="button" style={{textTransform: 'none', color:"white", fontSize:"14px", fontWeight:400}}>
                                    Valider
                                </Typography>
                            </Button>
                        </div>

                    </DialogActions>
                </Dialog>
                <Dialog open={isAPIDialogOpen} onClose={handleAPICloseOutside}
                        fullWidth={!isLargeScreen}
                        PaperProps={{
                            style: {
                                minWidth: isLargeScreen ? '500px' : 'auto',
                                maxWidth: isLargeScreen ? '500px' : 'auto'
                            },
                        }}
                >

                    <DialogContent style={{backgroundColor:"white"}}>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <span style={{fontWeight:700, fontSize:"16px"}}>
                                Ajoutez votre clé API Brevo
                            </span>
                            <div style={{display:"flex", flexDirection:"column", marginTop:"12px" }}>
                                <TextField onChange={handleAPIKEYChange} value={tempBrevoApiKey} className="field-style"
                                           label="Ecrire"  variant="outlined" name="apikey" />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions style={{backgroundColor:"white" }}>
                        <div style={{width:"100%", display:"flex", alignItems:"end", justifyContent:"end"}}>
                            <Button className="button-style-noborders-lil" style={{marginRight:"6px", marginBottom:"6px", backgroundColor:"black"}}
                                    variant="button" onClick={handleSaveApiKey}>
                                <Typography variant="button" style={{textTransform: 'none', color:"white", fontSize:"14px", fontWeight:400}}>
                                    Enregistrer
                                </Typography>
                            </Button>
                        </div>

                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isWarnDialogOpen}
                    onClose={handleWarnDialogClose}
                    fullWidth={!isLargeScreen}
                    PaperProps={{
                        style: {
                            minWidth: isLargeScreen ? '500px' : 'auto',
                            maxWidth: isLargeScreen ? '500px' : 'auto',
                        },
                    }}
                >
                    <DialogContent style={{ backgroundColor: "white" }}>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <span style={{fontWeight:700, fontSize:"16px"}}>
                                Supprimer une adresse
                            </span>
                            <Typography variant="body1" style={{fontWeight:"400px", fontSize:"14px", marginTop:"12px"}}>
                                Etes vous certain de vouloir supprimer l'adresse suivante?<br/><br/>
                                {formData.address[indexToDelete]}
                            </Typography>
                        </div>

                    </DialogContent>
                    <DialogActions style={{ backgroundColor: "white" }}>
                        <div style={{ width: "100%", display: "flex", alignItems: "end", justifyContent: "end" }}>
                            <Button className="button-style-noborders-lil" style={{marginRight:"6px", marginBottom:"6px", backgroundColor:"black"}} variant="button"
                                onClick={() => {
                                    handleDeleteAddress(); // Replace indexToDelete with the actual index you want to delete
                                }}
                            >
                                <Typography variant="button" style={{ textTransform: 'none', color:"white", fontSize:"14px"  }}>
                                    Confirmer
                                </Typography>
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>


                <Dialog
                    onClose={handlePassCloseOutside}
                    open={isDialogPassOpen}
                        fullWidth={!isLargeScreen}
                        PaperProps={{
                            style: {
                                minWidth: isLargeScreen ? '500px' : 'auto',
                                maxWidth: isLargeScreen ? '500px' : 'auto',
                            },
                        }}>
                    <DialogContent style={{backgroundColor:"white"}}>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <span style={{fontWeight:700, fontSize:"16px"}}>
                                Confirmer mon mot de passe pour changer mon email
                            </span>
                            <span style={{marginTop:"20px"}}>
                                Vous avez changé l'adresse email initiale <b>{userObject.email}</b> avec l'adresse <b>{formData.email}</b> , une fois le changement appliqué, votre mot de passe restera le même.
                            </span>
                            <div className="field-container-b">
                                <div className="title-field-container">
                                  <span className="text-field-style">
                                      Mot de passe
                                  </span>
                                </div>
                                <TextField type={showPassword ? "text" : "password"} className="field-style" label="Ecrire"
                                           onChange={handlePasswordChange} variant="outlined"
                                           InputProps={{
                                               endAdornment: (
                                                   <IconButton onClick={handleIconEyeClick} edge="end">
                                                       {showPassword ? <FaEye /> : <FaEyeSlash />}
                                                   </IconButton>
                                               ),
                                           }}/>
                            </div>
                        </div>

                    </DialogContent>
                    <DialogActions style={{backgroundColor:"white"}}>
                        <div style={{width:"100%", display:"flex", marginBottom:"10px", justifyContent:"end"}}>
                            <Button className="button-style-noborders-lil" disableElevation={true} style={{backgroundColor:"black", color:"white", display:"flex", marginRight:"14px"}} variant="contained" onClick={handlePassDialogClose}>
                                <Typography variant="button" style={{textTransform: 'none', fontSize:"14px", fontWeight:400}}>
                                    Valider
                                </Typography>
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={showBackdrop}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <SnackbarWithCustomBackground isOpen={showSnackbar} onClose={handleCloseSnackbar} message={snackMessage} style={{width:"100%"}} />
            </div>)}
        </div>

    )
}